import { Component, EventEmitter, Injector, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AppComponentBase } from '@shared/app-component-base';
import { RenderingState } from '@shared/models/enum-caa-state';
import { ContentPreview } from '@shared/models/file-info.model';
import { CAATypeDto, ExerciseDto, FileDto, FileServiceProxy, ModelCAADto } from '@shared/service-proxies/service-proxies';
import { Subscription } from 'rxjs';
import { ReconstructSequenceSnapshotModel } from './reconstruct-sequence-snapshot.model';
import { FileAnswer } from '@app/modelbasecaa/common-caa/file-answer';

@Component({
    selector: 'reconstruct-sequence-caa',
    templateUrl: './reconstruct-sequence-caa.component.html',
    styleUrls: ['./reconstruct-sequence-caa.component.css'],
})
export class ReconstructSequenceCaaComponent extends AppComponentBase implements OnDestroy, OnChanges, OnInit {
    MIN_SEQUENCE_LENGTH = 2;
    MAX_SEQUENCE_LENGTH = 10;

    @Input() caaType: CAATypeDto;
    @Input() state: RenderingState;
    @Input() modelCaa: ModelCAADto;
    @Input() correcting: boolean = false;
    @Input() exerciseExtendedDto: ExerciseDto;
    @Output() saveEvent: EventEmitter<ModelCAADto> = new EventEmitter<ModelCAADto>();
    @Output() notifyCorrectAnswerEvent: EventEmitter<string> = new EventEmitter<string>();
    @Output() notifyExerciseCompletedEvent: EventEmitter<string> = new EventEmitter<string>();
    states = RenderingState;
    snapShotObj: ReconstructSequenceSnapshotModel = new ReconstructSequenceSnapshotModel();
    questionFile: ContentPreview;
    answersFiles: ContentPreview[] = [];
    rightAnswersOrderedFiles: ContentPreview[] = [];
    protected subscription: Subscription = new Subscription();
    dropContainerVisible = false;

    answerImagesPromises: Promise<ContentPreview>[];
    answerImages: ContentPreview[] = [];

    constructor(protected fileService: FileServiceProxy, protected sanitizer: DomSanitizer, protected injector: Injector) {
        super(injector);
    }

    ngOnInit() {}

    ngOnChanges(changes: SimpleChanges): void {
        switch (this.state) {
            case RenderingState.create:
                {
                    this.snapShotObj = new ReconstructSequenceSnapshotModel();
                }
                break;
            case RenderingState.edit:
                {
                    this.snapShotObj = JSON.parse(this.modelCaa.excerciseSnapshotJson);
                    this.populateQuestion();
                    this.populateAnswers();
                }
                break;
            case RenderingState.executing:
                {
                    this.snapShotObj =
                        this.exerciseExtendedDto.executedJson != null ? JSON.parse(this.exerciseExtendedDto.executedJson) : JSON.parse(this.modelCaa.excerciseSnapshotJson);
                    this.populateAnswers();
                }
                break;
        }
        if (this.exerciseExtendedDto?.isDone) {
            this.snapShotObj = JSON.parse(this.exerciseExtendedDto?.executedJson);
        }
        this.getImages();
        this.populateQuestion();
    }

    isGranted(permissionName: string): boolean {
        return this.permission.isGranted(permissionName);
    }

    getImages() {
        if (this.state !== RenderingState.create) {
            this.answerImagesPromises = this.snapShotObj.sequence.rightAnswersOrdered.map((answer: FileAnswer) => {
                return new Promise<ContentPreview>((resolve) => {
                    this.subscription.add(
                        this.fileService.get(answer.fileId).subscribe((res: FileDto) => {
                            resolve(new ContentPreview(res, this.sanitizer, answer.description));
                        })
                    );
                });
            });
            Promise.all(this.answerImagesPromises).then((res: ContentPreview[]) => {
                this.answerImages = res;
                this.populateAnswers();
            });
        }
    }

    populateQuestion() {
        if (this.snapShotObj.sequence.question.fileId) {
            const file = new FileDto();
            file.id = this.snapShotObj.sequence.question.fileId;
            let sub = this.fileService.get(file.id).subscribe((file: FileDto) => {
                this.questionFile = new ContentPreview(file, this.sanitizer, '');
            });
            this.subscription.add(sub);
        }
    }

    populateAnswers() {
        this.answersFiles = [];
        if (this.answerImages?.length) {
            this.snapShotObj.sequence.rightAnswersOrdered.forEach((answer: FileAnswer, i: number) => {
                if (!answer.isHidden) {
                    this.answersFiles.push(this.answerImages[i]);
                    if (this.state === RenderingState.edit) {
                        this.showRightAnswer(this.answerImages[i], answer.description);
                    }
                } else {
                    if (this.state === RenderingState.executing || this.exerciseExtendedDto?.isDone) {
                        this.showRightAnswer(this.answerImages[i], answer.description);
                    }
                }
            });
        }
        this.shuffleArray(this.answersFiles);
    }

    showRightAnswer(file: ContentPreview, description) {
        const index = this.snapShotObj.sequence.rightAnswersOrdered.findIndex((el: FileAnswer) => el != null && el.fileId == file.id);
        this.rightAnswersOrderedFiles[index] = file;
    }

    dropQuestion(event) {
        if (event.isPointerOverContainer) {
            this.questionFile = event.item.data;
            this.snapShotObj.sequence.question = new FileAnswer(this.questionFile.id, false, this.questionFile.description);
        }
    }
    /**
     * Method called in create or edit, that set the right image of a box
     * @param event
     * @param index
     */
    dropCorrectAnswer(event, index) {
        if (event.isPointerOverContainer) {
            const answerFileDropped: ContentPreview = event.item.data;
            if (!this.checkAlreadyUsedImage(answerFileDropped)) {
                this.rightAnswersOrderedFiles[index] = answerFileDropped;
                this.snapShotObj.sequence.rightAnswersOrdered[index] = new FileAnswer(answerFileDropped.id, false);
            } else {
                if (event.previousContainer.id === 'answers-list') {
                    const id = this.rightAnswersOrderedFiles.findIndex((el: ContentPreview) => el != null && el.id == answerFileDropped.id);
                    [this.rightAnswersOrderedFiles[index], this.rightAnswersOrderedFiles[id]] = [this.rightAnswersOrderedFiles[id], this.rightAnswersOrderedFiles[index]];
                    [this.snapShotObj.sequence.rightAnswersOrdered[index], this.snapShotObj.sequence.rightAnswersOrdered[id]] = [
                        this.snapShotObj.sequence.rightAnswersOrdered[id],
                        this.snapShotObj.sequence.rightAnswersOrdered[index],
                    ];
                } else abp.notify.warn(this.l('ImageAlreadyUsed'));
            }
        }
    }

    dropAnswerOnQuestion(event, index) {
        if (event.isPointerOverContainer) {
            let droppedFile: ContentPreview = event.item.data;
            if (droppedFile.id == this.snapShotObj.sequence.rightAnswersOrdered[index].fileId && this.canAnswer(index)) {
                this.snapShotObj.sequence.rightAnswersOrdered[index].isHidden = true;
                this.rightAnswersOrderedFiles[index] = droppedFile;
                this.hideCorrectAnswerFromAvailable(droppedFile);
                this.notifyCorrectAnswerEvent.emit(JSON.stringify(this.snapShotObj));
            }
        }
    }

    /**
     * Check if the previous answer was given
     */
    canAnswer(droppedIndex) {
        if (droppedIndex > 0) {
            return this.snapShotObj.sequence.rightAnswersOrdered[droppedIndex - 1].isHidden;
        } else {
            return true;
        }
    }

    hideCorrectAnswerFromAvailable(droppedFile) {
        const removingIndex = this.answersFiles.indexOf(droppedFile);
        this.answersFiles.splice(removingIndex, 1);
    }

    checkAlreadyUsedImage(file: ContentPreview) {
        const id1 = this.snapShotObj.sequence.rightAnswersOrdered.findIndex((el: FileAnswer) => el != null && el.fileId == file.id);
        if (id1 != -1) {
            return true;
        }
        return this.questionFile?.id == file.id;
    }

    shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
    }
    isExerciseValid() {
        return this.snapShotObj.sequence.rightAnswersOrdered.length != this.snapShotObj.sequence.answersNumber;
    }

    save() {
        this.modelCaa.excerciseSnapshotJson = JSON.stringify(this.snapShotObj);
        this.saveEvent.emit(this.modelCaa);
    }
    addNewAnswer() {
        this.snapShotObj.sequence.answersNumber++;
    }
    /**
     * Remove answer from arrays
     * @param index
     */
    removeAnswer(index) {
        this.snapShotObj.sequence.answersNumber--;
        this.rightAnswersOrderedFiles.splice(index, 1);
        this.snapShotObj.sequence.rightAnswersOrdered.splice(index, 1);
    }
    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    showDropContainer() {
        this.dropContainerVisible = true;
    }

    hideDropContainer() {
        this.dropContainerVisible = false;
    }
}
