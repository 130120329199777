<ng-container *ngIf="state === states.executing; else modifingCreatingTemplate">
    <div style="min-width: 44em" class="d-flex flex-row align-items-start justify-content-center bg-white border-radius-default shadow-default" cdkDropListGroup>
        <div class="d-flex flex-column align-items-center justify-content-between">
            <div class="d-flex flex-column px-4 my-4 vertical-classification-container">
                <div class="p-2 bg-white shadow-default question-container">
                    <img alt="" [src]="questionFile?.signedUrl" class="w-100 h-100 question" />
                </div>
            </div>
            <div class="d-flex flex-row align-items-start justify-content-center ml-5 mr-5">
                <div *ngFor="let item of [].constructor(snapShotObj.sequence.answersNumber); let i = index" class="d-flex flex-column px-2 mb-4 justify-content-center">
                    <div
                        *ngIf="!rightAnswersOrderedFiles[i]?.signedUrl"
                        id="answers-list"
                        class="p-2 bg-white shadow-default answer-container"
                        [ngClass]="dropContainerVisible ? 'drop-container' : ''"
                        cdkDropList
                        (cdkDropListDropped)="dropAnswerOnQuestion($event, i)"
                    ></div>

                    <div *ngIf="rightAnswersOrderedFiles[i]?.signedUrl" class="p-2 bg-white shadow-default answer-container">
                        <img alt="" [src]="rightAnswersOrderedFiles[i]?.signedUrl" class="w-100 h-100 border-radius-default possible-answer-image" />
                    </div>
                </div>
            </div>
            <div class="d-flex flex-column align-items-center justify-content-center">
                <p class="text-heading h4 text-blue text-center mb-2">
                    {{ 'ShadowCAAAnswers' | localize }}
                </p>
                <div class="d-flex flex-row align-items-start justify-content-center">
                    <content-answers-list
                        class="d-flex d-flex-row sequence-answers-list"
                        #contentAnswersList
                        [state]="state"
                        [answers]="answersFiles"
                        [isDragDisabled]="exerciseExtendedDto?.isDone"
                        (dragStartedEvent)="showDropContainer()"
                        (dragEndedEvent)="hideDropContainer()"
                    >
                    </content-answers-list>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #modifingCreatingTemplate>
    <div class="d-flex flex-row align-items-start justify-content-center">
        <div class="d-flex flex-column px-4 my-4 vertical-classification-container">
            <p class="text-heading h4 text-blue text-center mb-2">
                {{ 'ShadowCAAQuestion' | localize }}
            </p>
            <div class="p-2 bg-white shadow-default question-container">
                <img
                    alt=""
                    [src]="questionFile?.signedUrl ? questionFile?.signedUrl : '/assets/img/image-placeholder-exercise.png'"
                    class="w-100 h-100 border-radius-default question"
                    cdkDropList
                    (cdkDropListDropped)="dropQuestion($event)"
                />
            </div>
        </div>
    </div>
    <div class="d-flex flex-row flex-wrap align-items-start justify-content-center">
        <div *ngFor="let item of [].constructor(snapShotObj.sequence.answersNumber); let i = index" class="d-flex flex-column px-2 my-4">
            <button
                *ngIf="!exerciseExtendedDto?.isDone"
                type="button"
                [disabled]="snapShotObj.sequence.answersNumber <= MIN_SEQUENCE_LENGTH"
                class="btn btn-default btn-block bg-red mb-2"
                (click)="removeAnswer(i)"
            >
                <em class="fa fa-trash d-inline-block ml-2"></em>
            </button>

            <div id="answers-list" class="p-2 bg-white shadow-default answer-container" cdkDropList (cdkDropListDropped)="dropCorrectAnswer($event, i)">
                <img
                    alt=""
                    [src]="rightAnswersOrderedFiles[i]?.signedUrl ? rightAnswersOrderedFiles[i]?.signedUrl : '/assets/img/image-placeholder-exercise.png'"
                    class="w-100 h-100 border-radius-default possible-answer-image"
                    cdkDrag
                    [cdkDragData]="rightAnswersOrderedFiles[i]"
                    [cdkDragDisabled]="exerciseExtendedDto?.isDone"
                />
            </div>
        </div>
        <div *ngIf="snapShotObj.sequence.answersNumber < MAX_SEQUENCE_LENGTH && !exerciseExtendedDto?.isDone" class="d-flex flex-column px-2 my-4">
            <div class="p-2 bg-white shadow-default answer-container">
                <div class="card-content d-flex flex-row align-items-center justify-content-center">
                    <div class="user-not-selected cursor-pointer text-center w-100" (click)="addNewAnswer()">
                        <em class="fas fa-plus text-grey h1 d-block"></em>
                        <span class="h6 text-grey text-bold text-capitalize"> {{ 'AddAnswer' | localize }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-12 d-flex justify-content-end">
            <button type="button" class="btn btn-primary" *ngIf="!exerciseExtendedDto?.isDone" (click)="save()" [disabled]="isExerciseValid()">{{ 'Save' | localize }}</button>
        </div>
    </div>
</ng-template>
