<form #synthesisForm="ngForm">
    <div class="row" *ngIf="!disabledWritingText">
        <div class="col-12 col-md-4">
            <div class="form-group">
                <label>{{ 'PercentageOfTextLength' | localize }}</label>
                <input max="100" min="0" class="form-control" type="number" name="percentage" [(ngModel)]="snapshotObj.percentage" required />
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <div class="form-group">
                <label>{{ 'Text' | localize }}</label>
                <!--            BUILD CKEDITOR ONLINE: https://ckeditor.com/ckeditor-5/online-builder/-->
                <!--            INTEGRAZIONE CON ANGULAR: https://ckeditor.com/docs/ckeditor5/latest/installation/integrations/angular.html -->
                <!--            DOCUMENTAZIONE SU https://ckeditor.com/docs/ckeditor5/latest/installation/integrations/angular.html#config-->
                <!--            CONFIGURAZIONE PER ANGULAR: https://ckeditor.com/docs/ckeditor5/latest/installation/integrations/angular.html#config-->
                <div [ngClass]="disabledWritingText || correcting ? 'bg-disabled' : 'bg-white'" (keydown)="preventWritingIfExecuting($event)">
                    <ckeditor
                        #ckEditor
                        [disabled]="correcting"
                        (ngModelChange)="highlight($event)"
                        *ngIf="modelCaa"
                        [editor]="Editor"
                        name="question"
                        [(ngModel)]="snapshotObj.question"
                    ></ckeditor>
                </div>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="state !== states.create && state !== states.edit">
        <div class="col-12">
            <div class="form-group">
                <label>{{ 'Synthesis' | localize }} ({{ 'MaxLengthAllowed' | localize }}: {{ textLength }})</label>
                <textarea
                    style="min-height: 200px"
                    name="answer"
                    *ngIf="modelCaa"
                    [maxLength]="textLength"
                    [disabled]="exerciseExtendedDto?.isDone || !disabledWritingText"
                    class="form-control"
                    [(ngModel)]="snapshotObj.answer"
                ></textarea>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="!correcting">
        <div class="col-12">
            <ng-container *ngIf="state === states.executing; else noExecutingTemplate">
                <button type="button" class="btn btn-primary" (click)="saveAnswer()">
                    {{ 'Save' | localize }}
                </button>
            </ng-container>
            <ng-template #noExecutingTemplate>
                <button *ngIf="isGranted(permissionConsts.Exercise_Update)" [disabled]="synthesisForm.form.invalid" type="button" class="btn btn-primary" (click)="saveFn()">
                    {{ 'Save' | localize }}
                </button>
            </ng-template>
        </div>
    </div>
</form>
