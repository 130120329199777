import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'recreation-caa',
  templateUrl: './recreation-caa.component.html',
  styleUrls: ['./recreation-caa.component.css']
})
export class RecreationCaaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
