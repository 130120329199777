import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'formatMoment',
})
export class FormatMomentPipe implements PipeTransform {
    public transform(value: number): string {
        const momentDate = moment.utc(value * 1000);
        return momentDate.format('HH') + 'h ' + momentDate.format('mm') + 'm';
    }
}
