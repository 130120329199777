// @angular
import { NgModule, ModuleWithProviders } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';

// External libs
import { NgxPaginationModule } from 'ngx-pagination';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

// Angular Material
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatStepperModule } from '@angular/material/stepper';
import { MatInputModule } from '@angular/material/input';

// Shared services
import { AppSessionService } from './session/app-session.service';
import { AppUrlService } from './nav/app-url.service';
import { AppAuthService } from './auth/app-auth.service';
import { AppRouteGuard } from './auth/auth-route-guard';
import { LocalizePipe } from '@shared/pipes/localize.pipe';
import { LayoutStoreService } from './layout/layout-store.service';

import { AbpPaginationControlsComponent } from './components/pagination/abp-pagination-controls.component';
import { AbpValidationSummaryComponent } from './components/validation/abp-validation.summary.component';
import { AbpModalHeaderComponent } from './components/modal/abp-modal-header.component';
import { AbpModalFooterComponent } from './components/modal/abp-modal-footer.component';

import { BusyDirective } from './directives/busy.directive';
import { EqualValidator } from './directives/equal-validator.directive';
import { FormatTimePipe } from './pipes/time-format.pipe';

import { QuizCaaComponent } from '@app/modelbasecaa/quiz-caa/quiz-caa.component';
import { ShadowCaaComponent } from '@app/modelbasecaa/shadow-caa/shadow-caa.component';
import { RenderingModelCaaComponent } from './components/rendering-modelcaa/rendering-modelcaa.component';
import { VerticalClassificationCaaComponent } from '@app/modelbasecaa/vertical-classification-caa/vertical-classification-caa.component';
import { HorizontalClassificationCaaComponent } from '@app/modelbasecaa/horizontal-classification-caa/horizontal-classification-caa.component';
import { ReconstructSequenceCaaComponent } from '@app/modelbasecaa/reconstruct-sequence-caa/reconstruct-sequence-caa.component';
import { RecreationCaaComponent } from '@app/modelbasecaa/recreation-caa/recreation-caa.component';
import { SnackCaaComponent } from '@app/modelbasecaa/snack-caa/snack-caa.component';
import { SimpleContentCaaComponent } from '@app/modelbasecaa/simple-content-caa/simple-content-caa.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { FormatMomentPipe } from './pipes/moment-format.pipe';
import { PreferencesCaaComponent } from '@app/modelbasecaa/preferences-caa/preferences-caa.component';
import { ContentAnswersListComponent } from './components/content-answers-list/content-answers-list.component';
import { DxDiagramModule, DxLoadPanelModule } from '@node_modules/devextreme-angular';
import { MapCaaComponent } from '@app/modelbasecaa/map-caa/map-caa.component';
import { AdvancedSequenceCaaComponent } from '@app/modelbasecaa/advanced-sequence-caa/advanced-sequence-caa.component';
import { CKEditorModule } from '@node_modules/@ckeditor/ckeditor5-angular';
import { CaaSynthesisComponent } from '@app/modelbasecaa/sythesis-caa/caa-synthesis.component';
import { SafePipe } from './pipes/safe.pipe';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,

        // Angular Material
        MatFormFieldModule,
        MatStepperModule,
        MatRadioModule,
        MatInputModule,

        NgxPaginationModule,
        DragDropModule,
        TooltipModule.forRoot(),
        TypeaheadModule.forRoot(),
        PdfViewerModule,
        DxDiagramModule,
        CKEditorModule,
        DxLoadPanelModule,

    ],
    declarations: [
        AbpPaginationControlsComponent,
        AbpValidationSummaryComponent,
        AbpModalHeaderComponent,
        AbpModalFooterComponent,
        LocalizePipe,
        BusyDirective,
        EqualValidator,
        QuizCaaComponent,
        RenderingModelCaaComponent,
        FormatTimePipe,
        ShadowCaaComponent,
        ContentAnswersListComponent,
        VerticalClassificationCaaComponent,
        HorizontalClassificationCaaComponent,
        ReconstructSequenceCaaComponent,
        AdvancedSequenceCaaComponent,
        RecreationCaaComponent,
        SnackCaaComponent,
        SimpleContentCaaComponent,
        FormatMomentPipe,
        PreferencesCaaComponent,
        MapCaaComponent,
        CaaSynthesisComponent,
        SafePipe,
    ],
    exports: [
        AbpPaginationControlsComponent,
        AbpValidationSummaryComponent,
        AbpModalHeaderComponent,
        AbpModalFooterComponent,
        LocalizePipe,
        BusyDirective,
        EqualValidator,
        FormatTimePipe,
        DragDropModule,
        QuizCaaComponent,
        ShadowCaaComponent,
        RenderingModelCaaComponent,
        ContentAnswersListComponent,
        VerticalClassificationCaaComponent,
        HorizontalClassificationCaaComponent,
        ReconstructSequenceCaaComponent,
        AdvancedSequenceCaaComponent,
        FormatMomentPipe,
        CommonModule,
        TypeaheadModule,
        MapCaaComponent,
        CaaSynthesisComponent,
        SafePipe
    ],
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [AppSessionService, AppUrlService, AppAuthService, AppRouteGuard, LayoutStoreService],
        };
    }
}
