<div class="rendering-container">
    <ng-container [ngSwitch]="caaType?.staticCode">
        <h3 class="mt-5 mb-3 text-center">{{ modelCaa.description }}</h3>

        <div *ngSwitchCase="constants.quiz" class="border-radius-default shadow-default bg-white p-4">
            <quiz-caa
                [caaType]="caaType"
                [state]="state"
                [modelCaa]="modelCaa"
                (saveEvent)="save($event)"
                [exerciseExtendedDto]="exerciseExtendedDto"
                (notifyCorrectAnswerEvent)="notifyCorrectAnswer($event)"
                [isOperator]="isOperator"
            ></quiz-caa>
        </div>

        <div *ngSwitchCase="constants.map">
            <app-map-caa
                [modelCaa]="modelCaa"
                [exerciseExtendedDto]="exerciseExtendedDto"
                [state]="state"
                [caaType]="caaType"
                (notifyCorrectAnswerEvent)="notifyCorrectAnswer($event)"
                (saveEvent)="save($event)"
            ></app-map-caa>
        </div>

        <div *ngSwitchCase="constants.shadow" class="d-flex mb-4">
            <shadow-caa
                [caaType]="caaType"
                [state]="state"
                [modelCaa]="modelCaa"
                (saveEvent)="save($event)"
                [exerciseExtendedDto]="exerciseExtendedDto"
                (notifyExerciseCompletedEvent)="notifyExerciseCompleted($event)"
                [isOperator]="isOperator"
            >
            </shadow-caa>
        </div>

        <div *ngSwitchCase="constants.verticalclassification">
            <vertical-classification-caa
                [caaType]="caaType"
                [state]="state"
                [modelCaa]="modelCaa"
                (saveEvent)="save($event)"
                [exerciseExtendedDto]="exerciseExtendedDto"
                (notifyCorrectAnswerEvent)="notifyCorrectAnswer($event)"
                (notifyExerciseCompletedEvent)="notifyExerciseCompleted($event)"
            >
            </vertical-classification-caa>
        </div>

        <div *ngSwitchCase="constants.horizontalclassification">
            <horizontal-classification-caa
                [caaType]="caaType"
                [state]="state"
                [modelCaa]="modelCaa"
                (saveEvent)="save($event)"
                [exerciseExtendedDto]="exerciseExtendedDto"
                (notifyCorrectAnswerEvent)="notifyCorrectAnswer($event)"
                (notifyExerciseCompletedEvent)="notifyExerciseCompleted($event)"
            >
            </horizontal-classification-caa>
        </div>

        <div *ngSwitchCase="constants.advancedSequence">
            <advanced-sequence-caa
                [caaType]="caaType"
                [state]="state"
                [modelCaa]="modelCaa"
                (saveEvent)="save($event)"
                [exerciseExtendedDto]="exerciseExtendedDto"
                (notifyCorrectAnswerEvent)="notifyCorrectAnswer($event)"
                (notifyWrongAnswerEvent)="notifyWrongAnswer($event)"
                (notifyExerciseCompletedEvent)="notifyExerciseCompleted($event)"
            >
            </advanced-sequence-caa>
        </div>

        <div *ngSwitchCase="constants.synthesis">
            <synthesis-caa
                [modelCaa]="modelCaa"
                (notifyCorrectAnswerEvent)="notifyCorrectAnswer($event)"
                [caaType]="caaType"
                [state]="state"
                [exerciseExtendedDto]="exerciseExtendedDto"
                (saveEvent)="save($event)"
            ></synthesis-caa>
        </div>

        <div *ngSwitchCase="constants.sequence">
            <reconstruct-sequence-caa
                [caaType]="caaType"
                [state]="state"
                [modelCaa]="modelCaa"
                (saveEvent)="save($event)"
                [exerciseExtendedDto]="exerciseExtendedDto"
                (notifyCorrectAnswerEvent)="notifyCorrectAnswer($event)"
                (notifyExerciseCompletedEvent)="notifyExerciseCompleted($event)"
            >
            </reconstruct-sequence-caa>
        </div>

        <div *ngSwitchCase="constants.recreation">
            <recreation-caa></recreation-caa>
        </div>

        <div *ngSwitchCase="constants.snacktime">
            <snack-caa></snack-caa>
        </div>

        <div *ngSwitchCase="constants.simplecontent">
            <simple-content-caa [caaType]="caaType" [state]="state" [modelCaa]="modelCaa" (saveEvent)="save($event)"></simple-content-caa>
        </div>

        <div *ngSwitchCase="constants.preferences">
            <preferences-caa
                [caaType]="caaType"
                [state]="state"
                [modelCaa]="modelCaa"
                (saveEvent)="save($event)"
                [exerciseExtendedDto]="exerciseExtendedDto"
                (notifyCorrectAnswerEvent)="notifyCorrectAnswer($event)"
                (notifyExerciseCompletedEvent)="notifyExerciseCompleted($event)"
            ></preferences-caa>
        </div>
    </ng-container>
</div>
