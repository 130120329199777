export class CAATypeStaticCodeCostant {
    simplecontent = 'simplecontent';
    quiz = 'quiz';
    shadow = 'shadow';
    verticalclassification = 'verticalclassification';
    horizontalclassification = 'horizontalclassification';
    sequence = 'sequence';
    recreation = 'recreation';
    preferences = 'preferences';
    snacktime = 'snacktime';
    map: string = 'conceptualmap';
    advancedSequence = 'advancedSequence';
    synthesis = 'synthesis';
}
