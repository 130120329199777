import { Injectable } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
@Injectable({
    providedIn: 'root',
})
export class TextToSpeechService {
    synth = window.speechSynthesis;
    speechSynthesisUtterance = new SpeechSynthesisUtterance();
    constructor(public platform: Platform) {
        if (this.platform.FIREFOX) {
            this.setCurrentLocalVoice(this.synth.getVoices());
        } else if (!this.platform.SAFARI) {
            // If not firefox, safari and is not IOS, wait browser to load voices
            this.synth.addEventListener('voiceschanged', () => {
                this.setCurrentLocalVoice(speechSynthesis.getVoices());
            });
        }
    }

    setCurrentLocalVoice(voices: SpeechSynthesisVoice[]) {
        this.speechSynthesisUtterance.voice = voices.find((voice) => voice.lang.includes(abp.localization.currentLanguage.name));
    }

    speak(text: string) {
        if (text && text.trim() !== '') {
            this.speechSynthesisUtterance.text = text;
            this.synth.cancel();
            this.synth.speak(this.speechSynthesisUtterance);
        }
    }
}
