import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { RenderingState } from '@shared/models/enum-caa-state';
import { CAATypeDto, ExerciseDto, FileDto, FileServiceProxy, ModelCAADto } from '@shared/service-proxies/service-proxies';
import { ShadowSnapshotModel } from './shadow-snapshot.model';
import { ContentPreview } from '@shared/models/file-info.model';
import { FileAnswer } from '../common-caa/file-answer';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { ContentAnswersListComponent } from '@shared/components/content-answers-list/content-answers-list.component';
import { ContentAllowedConfiguration } from '@shared/models/content-configuration.model';

@Component({
    selector: 'shadow-caa',
    templateUrl: './shadow-caa.component.html',
    styleUrls: ['./shadow-caa.component.css'],
})
export class ShadowCaaComponent implements OnChanges, OnDestroy {
    @ViewChild('contentAnswersList') contentAnswersList: ContentAnswersListComponent;
    @Input() caaType: CAATypeDto;
    @Input() state: RenderingState;
    @Input() modelCaa: ModelCAADto;
    @Input() exerciseExtendedDto: ExerciseDto;
    @Input() isOperator: boolean;
    @Input() correcting: boolean = false;
    @Output() saveEvent: EventEmitter<ModelCAADto> = new EventEmitter<ModelCAADto>();
    @Output() notifyExerciseCompletedEvent: EventEmitter<string> = new EventEmitter<string>();
    contentAllowed: ContentAllowedConfiguration = new ContentAllowedConfiguration(true);
    questionFile: ContentPreview;
    rightAnswerFile: ContentPreview;
    states = RenderingState;
    answers: ContentPreview[] = [];
    snapShotObj: ShadowSnapshotModel = new ShadowSnapshotModel();
    private subscription: Subscription = new Subscription();
    dropContainerVisible = false;
    constructor(private fileService: FileServiceProxy, private sanitizer: DomSanitizer) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (this.exerciseExtendedDto?.isDone) {
            this.snapShotObj = JSON.parse(this.exerciseExtendedDto?.executedJson) as ShadowSnapshotModel;
        }
        switch (this.state) {
            case RenderingState.create:
                {
                    this.snapShotObj = new ShadowSnapshotModel();
                }
                break;
            case RenderingState.edit:
                {
                    this.snapShotObj = JSON.parse(this.modelCaa.excerciseSnapshotJson);
                    this.populateQuestion();
                    this.populateAnswers();
                    this.populateRightAnswer();
                }
                break;
            case RenderingState.executing:
                {
                    this.snapShotObj =
                        this.exerciseExtendedDto.executedJson != null ? JSON.parse(this.exerciseExtendedDto.executedJson) : JSON.parse(this.modelCaa.excerciseSnapshotJson);
                    this.populateQuestion(this.exerciseExtendedDto.isDone);
                    this.populateAnswers();
                }
                break;
        }
        if (this.correcting) {
            this.snapShotObj = { ...JSON.parse(this.modelCaa.excerciseSnapshotJson), ...JSON.parse(this.exerciseExtendedDto.executedJson) } as ShadowSnapshotModel;
            const excerciseSnapshotJson: ShadowSnapshotModel = JSON.parse(this.modelCaa.excerciseSnapshotJson) as ShadowSnapshotModel;
            this.snapShotObj.shadow.shadowAnswers = excerciseSnapshotJson.shadow.shadowAnswers;
            this.populateQuestion();
            this.populateAnswers();
            this.populateRightAnswer();
        }
    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }

    populateRightAnswer() {
        let rightAnswerFileId = this.snapShotObj.shadow.answerImageId;
        let sub;
        if (rightAnswerFileId) {
            const file = new FileDto();
            file.id = rightAnswerFileId;
            sub = this.fileService.get(file.id).subscribe((file: FileDto) => {
                this.rightAnswerFile = new ContentPreview(file);
            });
        }
        this.subscription.add(sub);
    }

    populateQuestion(isExerciseDone?) {
        let sub;
        const idAnswer = isExerciseDone ? this.snapShotObj.shadow.answerImageId : this.snapShotObj.shadow.questionImageId;
        const file = new FileDto();
        file.id = idAnswer;
        sub = this.fileService.get(file.id).subscribe((file: FileDto) => {
            this.questionFile = new ContentPreview(file, this.sanitizer, '');
        });
        this.subscription.add(sub);
    }

    populateAnswers() {
        let sub;
        this.answers = [];
        this.snapShotObj.shadow.shadowAnswers.forEach((answer) => {
            const file = new FileDto();
            file.id = answer.fileId;
            sub = this.fileService.get(file.id).subscribe((file: FileDto) => {
                if (!answer.isHidden) {
                    this.answers.push(new ContentPreview(file, this.sanitizer, answer.description));
                }
            });
        });
        this.subscription.add(sub);
    }

    save() {
        this.snapShotObj.shadow.shadowAnswers = [];
        this.contentAnswersList.answers.forEach((answer) => {
            this.snapShotObj.shadow.shadowAnswers.push(new FileAnswer(answer.id, false, answer.description));
        });
        this.modelCaa.excerciseSnapshotJson = JSON.stringify(this.snapShotObj);
        this.saveEvent.emit(this.modelCaa);
    }

    dropQuestion(event) {
        if (event.isPointerOverContainer) {
            this.questionFile = event.item.data;
            this.snapShotObj.shadow.questionImageId = this.questionFile.id;
        }
    }

    dropRightAnswer(event) {
        if (event.isPointerOverContainer) {
            this.rightAnswerFile = event.item.data;
            this.snapShotObj.shadow.answerImageId = this.rightAnswerFile.id;
        }
    }

    dropAnswerOnQuestion(event) {
        if (event.isPointerOverContainer) {
            let droppedFile: ContentPreview = event.item.data;
            if (droppedFile.id == this.snapShotObj.shadow.answerImageId) {
                this.questionFile = droppedFile;
                const removingIndex = this.answers.indexOf(droppedFile);
                this.answers.splice(removingIndex, 1);
                const hidingIndex = this.snapShotObj.shadow.shadowAnswers.findIndex((x) => x.fileId === droppedFile.id);
                this.snapShotObj.shadow.shadowAnswers[hidingIndex].isHidden = true;
                this.notifyExerciseCompletedEvent.emit(JSON.stringify(this.snapShotObj));
            }
        }
    }

    showDropContainer() {
        this.dropContainerVisible = true;
    }

    hideDropContainer() {
        this.dropContainerVisible = false;
    }
}
