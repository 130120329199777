import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { RenderingState } from '@shared/models/enum-caa-state';
import { CAATypeDto, ExerciseDto, ModelCAADto } from '@shared/service-proxies/service-proxies';
import { CAATypeStaticCodeCostant } from '@shared/models/caa-type-static-code-costant';

@Component({
    selector: 'rendering-modelcaa',
    templateUrl: './rendering-modelcaa.component.html',
    styleUrls: ['./rendering-modelcaa.component.css'],
})
export class RenderingModelCaaComponent implements OnChanges {
    @Input() caaType: CAATypeDto;
    @Input() state: RenderingState;
    @Input() modelCaa: ModelCAADto;
    @Input() exerciseExtendedDto: ExerciseDto;
    @Input() isOperator: boolean;

    @Output() saveEvent: EventEmitter<ModelCAADto> = new EventEmitter<ModelCAADto>();
    @Output() notifyCorrectAnswerEvent: EventEmitter<string> = new EventEmitter<string>();
    @Output() notifyWrongAnswerEvent: EventEmitter<number> = new EventEmitter<number>();
    @Output() notifyExerciseCompletedEvent: EventEmitter<string> = new EventEmitter<string>();

    constants = new CAATypeStaticCodeCostant();

    constructor() {}

    save(modelCaa: ModelCAADto) {
        this.saveEvent.emit(modelCaa);
    }

    notifyCorrectAnswer(snapShotObj: string) {
        this.notifyCorrectAnswerEvent.emit(snapShotObj);
    }

    notifyWrongAnswer(id: number) {
        this.notifyWrongAnswerEvent.emit(id);
    }

    notifyExerciseCompleted(snapShotObj: string) {
        this.notifyExerciseCompletedEvent.emit(snapShotObj);
    }

    ngOnChanges(change: SimpleChanges) {
        console.log(change);
    }
}
