import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { AppPermissions } from '@shared/AppPermissions';

import { FeatureCheckerService, PermissionCheckerService } from 'abp-ng2-module';

import { AppSessionService } from '../session/app-session.service';
import { FeatureConsts } from '@shared/FeatureConst';

@Injectable()
export class AppRouteGuard implements CanActivate, CanActivateChild {
    constructor(private permissionChecker: PermissionCheckerService, private router: Router, private sessionService: AppSessionService,private featureCheckerService: FeatureCheckerService) {}

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        await this.sessionService.init().then((result: boolean) => {
            let canActivate = true;
            if (result) {
                if (!this.sessionService.user) {
                    this.router.navigate(['/account/login']);
                    return false;
                }
                if (!route.data || !route.data.permission && !route.data['feature']) {
                    return true;
                }

                if (this.permissionChecker.isGranted(route.data.permission)) {
                    return true;
                }
                if (route.data['permission']) canActivate = this.permissionChecker.isGranted(route.data['permission']);
                if (route.data['feature']) canActivate = canActivate && this.featureCheckerService.isEnabled(route.data['feature']);
                if (!canActivate) this.router.navigate([this.selectBestRoute()]);
                return false;
            } else {
                return false;
            }
        });

        return true;
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return this.canActivate(route, state);
    }

    selectBestRoute(): string {
        console.log(this.featureCheckerService.isEnabled(FeatureConsts.Courses))
        if (!this.sessionService.user) {
            return '/account/login';
        }

        if (this.permissionChecker.isGranted(AppPermissions.Pages_Users)) {
            return '/app/user';
        }

        if (this.permissionChecker.isGranted(AppPermissions.Pages_Centers)) {
            return '/app/center';
        }

        if (this.permissionChecker.isGranted(AppPermissions.Pages_Courses)) {
            return '/app/training-course';
        }

        if (this.permissionChecker.isGranted(AppPermissions.Pages_Activities)) {
            return '/app/activity';
        }

        return '/app/user-not-configured';
    }
}
