import { Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { QuizSnapShotModel } from '@app/modelbasecaa/quiz-caa/quiz-snapshot.model';
import { AppComponentBase } from '@shared/app-component-base';
import { ContentAllowedConfiguration } from '@shared/models/content-configuration.model';
import { RenderingState } from '@shared/models/enum-caa-state';
import { ContentPreview } from '@shared/models/file-info.model';
import { FileService } from '@shared/services/resources/file.service';

@Component({
    selector: 'content-answers-list',
    templateUrl: './content-answers-list.component.html',
    styleUrls: ['./content-answers-list.component.css'],
})
export class ContentAnswersListComponent extends AppComponentBase {
    @Output() notifyCorrectAnswerEvent: EventEmitter<QuizSnapShotModel> = new EventEmitter<QuizSnapShotModel>();
    @Output() dragStartedEvent: EventEmitter<void> = new EventEmitter<void>();
    @Output() dragEndedEvent: EventEmitter<void> = new EventEmitter<void>();
    @Output() removeAnswerEvent: EventEmitter<ContentPreview> = new EventEmitter<ContentPreview>();
    @Input() contentAllowed: ContentAllowedConfiguration = new ContentAllowedConfiguration();
    @Input() answers: ContentPreview[] = [];
    @Input() state: RenderingState;
    @Input('isDragDisabled') _isDragDisabled: boolean = false;
    @Input() enabledId: number;
    states = RenderingState;
    constructor(injector: Injector, public fileService: FileService) {
        super(injector);
    }

    notifyCorrectAnswer(snapShotObj: QuizSnapShotModel) {
        this.notifyCorrectAnswerEvent.emit(snapShotObj);
    }

    isDragDisabled(id: number): boolean {
        if (this._isDragDisabled) {
            return this._isDragDisabled;
        }
        if (this.enabledId) {
            return id !== this.enabledId;
        }
        return false;
    }

    drop(event) {
        if (event.previousContainer.id != 'answer-list-dnd' && event.isPointerOverContainer) {
            if (this.state == this.states.edit || this.state == this.states.create) {
                this.addAnswer(event);
            }
        }
    }

    removeAnswer(index) {
        this.removeAnswerEvent.emit(this.answers[index]);
        this.answers.splice(index, 1);
    }

    addAnswer(event) {
        let file: ContentPreview = event.item.data;
        let elIndex = this.answers.findIndex((el) => el == file && el);
        if (elIndex == -1) {
            if (this.fileService.isContentAllowed(file, this.contentAllowed)) {
                this.answers.push(file);
            } else {
                abp.message.warn(this.l('ContentNotAllowed'));
            }
        }
    }

    dragStarted(answer: ContentPreview) {
        this.textToSpeechImage(answer.description);
        this.dragStartedEvent.emit();
    }

    dragEnded() {
        this.dragEndedEvent.emit();
    }
}
