import { Component, EventEmitter, Injector, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { AppComponentBase } from '@shared/app-component-base';
import { RenderingState } from '@shared/models/enum-caa-state';
import { CAATypeDto, FileDto, FileServiceProxy, ModelCAADto } from '@shared/service-proxies/service-proxies';
import { Subscription } from 'rxjs';
import { SimpleContentSnapshotModel } from './simple-content-snapshot.model';

@Component({
    selector: 'simple-content-caa',
    templateUrl: './simple-content-caa.component.html',
    styleUrls: ['./simple-content-caa.component.css'],
})
export class SimpleContentCaaComponent extends AppComponentBase implements OnDestroy, OnChanges {
    @Input() caaType: CAATypeDto;
    @Input() state: RenderingState;
    @Input() modelCaa: ModelCAADto;
    @Output() saveEvent: EventEmitter<ModelCAADto> = new EventEmitter<ModelCAADto>();
    states = RenderingState;
    snapShotObj: SimpleContentSnapshotModel = new SimpleContentSnapshotModel();
    contentFile: FileDto;
    typeFile: string;

    private subscription: Subscription = new Subscription();
    pdfSource: ArrayBufferLike;
    constructor(private injector: Injector, private fileService: FileServiceProxy) {
        super(injector);
    }

    ngOnChanges(changes: SimpleChanges): void {
        switch (this.state) {
            case RenderingState.create:
                {
                    this.snapShotObj = new SimpleContentSnapshotModel();
                }
                break;
            default:
                {
                    this.snapShotObj = JSON.parse(this.modelCaa.excerciseSnapshotJson);
                    this.populateContentFile();
                }
                break;
        }
    }

    populateContentFile() {
        if (this.snapShotObj.fileDto && this.snapShotObj.fileDto.id) {
            this.fileService.get(this.snapShotObj.fileDto.id).subscribe((file: FileDto) => {
                this.contentFile = file;
                this.setType();
            });
        }
    }

    setType() {
        ['image', 'audio', 'video', 'pdf'].forEach((type: string) => {
            if (this.contentFile.mimeType.includes(type)) {
                this.typeFile = type;
            }
        });
    }

    droppedContent(event) {
        if (event.isPointerOverContainer) {
            this.contentFile = event.item.data;
            this.snapShotObj.fileDto = this.contentFile;
            this.setType();
        }
    }
    save() {
        this.modelCaa.excerciseSnapshotJson = JSON.stringify(this.snapShotObj);
        this.saveEvent.emit(this.modelCaa);
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
