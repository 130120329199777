<ng-container *ngIf="state === states.executing; else modifingCreatingTemplate">
    <div class="d-flex flex-row align-items-start justify-content-center classification-background">
        <div class="d-flex flex-column px-2 mb-4 shadowcaa-question-container">
            <p class="text-darkblue h4 pb-2">
                {{ 'ShadowCAAQuestion' | localize }}
            </p>
            <div class="right-answer-container p-2 bg-white shadow-default" [ngClass]="dropContainerVisible ? 'drop-container' : ''">
                <img
                    [src]="questionFile?.signedUrl"
                    cdkDropList
                    id="question-list-dnd"
                    class="w-100 h-100 border-radius-default right-answer-image"
                    (cdkDropListDropped)="dropAnswerOnQuestion($event)"
                    cdkDropListConnectedTo="library-list-dnd"
                />
            </div>
        </div>

        <div class="d-flex flex-column px-2 mb-4 shadowcaa-answers-container">
            <p class="text-darkblue h4 pb-2">
                {{ 'ShadowCAAAnswers' | localize }}
            </p>

            <div class="d-flex flex-grow-2" cdkDropListGroup>
                <content-answers-list
                    #contentAnswersList
                    [isDragDisabled]="exerciseExtendedDto.isDone"
                    [state]="state"
                    [answers]="answers"
                    [contentAllowed]="contentAllowed"
                    (dragStartedEvent)="showDropContainer()"
                    (dragEndedEvent)="hideDropContainer()"
                >
                </content-answers-list>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #modifingCreatingTemplate>
    <div class="d-flex flex-row align-items-start justify-content-center">
        <div class="d-flex flex-column px-2 mb-4 shadowcaa-question-container">
            <div>
                <p class="text-darkblue h4 pb-2">
                    {{ 'ShadowCAAQuestion' | localize }}
                </p>
                <div class="right-answer-container p-2 bg-white shadow-default" style="width: 20em; height: 22em">
                    <img
                        [src]="questionFile?.signedUrl ? questionFile?.signedUrl : '/assets/img/image-placeholder-exercise.png'"
                        cdkDropList
                        id="question-list-dnd"
                        class="w-100 h-100 border-radius-default right-answer-image"
                        (cdkDropListDropped)="dropQuestion($event)"
                        cdkDropListConnectedTo="library-list-dnd"
                    />
                </div>
            </div>

            <div>
                <p class="text-darkblue h4 pb-2">
                    {{ 'ShadowCAARightAnswer' | localize }}
                </p>
                <div class="right-answer-container p-2 bg-white shadow-default" style="width: 20em; height: 22em">
                    <img
                        [src]="rightAnswerFile?.signedUrl ? rightAnswerFile?.signedUrl : '/assets/img/image-placeholder-exercise.png'"
                        cdkDropList
                        id="question-list-dnd"
                        class="w-100 h-100 border-radius-default right-answer-image"
                        (cdkDropListDropped)="dropRightAnswer($event)"
                        cdkDropListConnectedTo="library-list-dnd"
                    />
                </div>
            </div>
        </div>

        <div class="d-flex flex-column px-2 mb-4 shadowcaa-answers-container">
            <p class="text-darkblue h4 pb-2">
                {{ 'ShadowCAAAnswers' | localize }}
            </p>

            <div class="d-flex flex-grow-2">
                <content-answers-list #contentAnswersList [state]="state" [answers]="answers" [contentAllowed]="contentAllowed"> </content-answers-list>
            </div>
        </div>
    </div>

    <button type="button" class="btn btn-primary" (click)="save()">{{ 'Save' | localize }}</button>
</ng-template>
