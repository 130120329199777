import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

import { AppConsts } from '@shared/AppConsts';
import { UtilsService } from 'abp-ng2-module';

@Injectable({
    providedIn: 'root',
})
export class SignalRAspNetCoreHelper {
    static autoReconnect = true;
    static maxTries = 8;
    static reconnectTime = 2500;

    static signalRLoaded = new ReplaySubject<boolean>();
    static increaseReconnectTime = (time) => {
        return time * 2;
    };

    static initSignalR(callback?: () => void): void {
        const encryptedAuthToken = new UtilsService().getCookieValue(AppConsts.authorization.encryptedAuthTokenName);

        abp.signalr = {
            autoConnect: true,
            connect: undefined,
            hubs: undefined,
            qs: AppConsts.authorization.encryptedAuthTokenName + '=' + encodeURIComponent(encryptedAuthToken),
            remoteServiceBaseUrl: AppConsts.remoteServiceBaseUrl,
            startConnection: undefined,
            url: '/signalr',
        };

        const script = document.createElement('script');

        script.onload = () => {
            this.signalRLoaded.next(true);
        };

        script.onerror = () => {
            this.signalRLoaded.next(false);
        };

        if (callback) {
            this.signalRLoaded.subscribe((loaded) => {
                if (loaded) {
                    callback();
                }
            });
        }
        script.src = AppConsts.appBaseUrl + 'assets/abp/abp.signalr-client.js';
        document.head.appendChild(script);
    }
}
