export class ContentAllowedConfiguration {
    imageAccepted: boolean;
    videoAccepted: boolean;
    audioAccepted: boolean;
    pdfAccepted: boolean;
    textAccepted: boolean;
    constructor(imageAccepted?, videoAccepted?, audioAccepted?, pdfAccepted?, textAccepted?) {
        this.imageAccepted = imageAccepted;
        this.videoAccepted = videoAccepted;
        this.audioAccepted = audioAccepted;
        this.pdfAccepted = pdfAccepted;
        this.textAccepted = textAccepted;
    }
}
