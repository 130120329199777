import { FileAnswer } from '../common-caa/file-answer';

class ShadowQuestion {
    description?: string;
    questionImageId: number;
    answerImageId: number;
    shadowAnswers: FileAnswer[] = [];
}

export class ShadowSnapshotModel {
    shadow: ShadowQuestion;
    constructor() {
        this.shadow = new ShadowQuestion();
    }
}
