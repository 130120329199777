import { FileAnswer } from '../common-caa/file-answer';

export class ClassificationQuestion {
    isVertical: boolean = false;
    question1: FileAnswer = new FileAnswer('', false);
    question2: FileAnswer = new FileAnswer('', false);
    question1RightAnswersId: number[] = [];
    question2RightAnswersId: number[] = [];
    classificationAnswers: FileAnswer[] = [];
}

export class ClassificationSnapshotModel {
    classification: ClassificationQuestion;
    constructor() {
        this.classification = new ClassificationQuestion();
    }
}
