
<dx-diagram 
    [readOnly]="correcting || exerciseExtendedDto?.isDone"
    #diagram
    class="diagram"
    [toolbox]="{ visibility: exerciseExtendedDto?.isDone ? 'disabled' : 'auto' }"
    [historyToolbar]="{ visible: !exerciseExtendedDto?.isDone }"
    [propertiesPanel]="{ visibility: exerciseExtendedDto?.isDone ? 'disabled' : 'auto' }"
    [viewToolbar]="{ visible: !exerciseExtendedDto?.isDone }"
    [contextMenu]="{ enabled: !exerciseExtendedDto?.isDone, commands: [] }"
    [mainToolbar]="{ visible: !exerciseExtendedDto?.isDone }"
>
    <!--    DOCUMENTAZIONE SU https://js.devexpress.com/Documentation/ApiReference/UI_Components/dxDiagram/Configuration/toolbox/-->
    <dxo-toolbox  #diagramGroup visibility="visible" [groups]="['general']" [showSearch]="false" [shapeIconsPerRow]="2">
        <!--        DOCUMENTAZIONE SU https://js.devexpress.com/Documentation/ApiReference/UI_Components/dxDiagram/Configuration/toolbox/groups/-->
        <dxi-group [category]="'general'" [shapes]="['rectangle', 'ellipse', 'text']" [expanded]="true"></dxi-group>
    </dxo-toolbox>
</dx-diagram>

<dx-load-panel
  #loadPanel
  shadingColor="rgba(0,0,0,0.4)"
  [(visible)]="isLoading"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
  [hideOnOutsideClick]="false"
>
</dx-load-panel>
  
<div class="d-flex justify-content-center mt-3" *ngIf="isEditing">
    <button class="btn btn-default" type="button" (click)="save()">
        {{ 'Save' | localize }}
    </button>
</div>
