import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FileDto } from '../service-proxies/service-proxies';

export class ContentPreview extends FileDto {
    fileSource: SafeUrl;
    description;
    isText: boolean;
    constructor(data: FileDto, sanitizer?: DomSanitizer, description?: string, isText?: boolean) {
        super(data);
        this.description = description == null ? '' : description;
        this.isText = isText;
    }
}
