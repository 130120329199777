<ng-container *ngIf="state === states.executing; else modifingCreatingTemplate">
    <div class="p-3 bg-white border-radius-default shadow-default align-items-center justify-content-center" style="min-height: 30em" cdkDropListGroup>
        <div *ngIf="availableImagesFiles.length > 1" class="row">
            <div class="col-4">
                <div *ngFor="let item of availableImagesFiles; let i = index" class="d-flex flex-column px-2 my-4">
                    <div id="answers-list" class="p-2 bg-white shadow-default answer-container" cdkDropList>
                        <img
                            alt=""
                            [src]="item.signedUrl ? item.signedUrl : '/assets/img/image-placeholder-exercise.png'"
                            class="w-100 h-100 border-radius-default possible-answer-image"
                            cdkDrag
                            [cdkDragData]="item"
                            (cdkDragStarted)="dragStarted(item)"
                            (cdkDragEnded)="dragEnded()"
                            (click)="textToSpeechImage(item.description)"
                            cdkDragBoundary=".rendering-container"
                        />
                    </div>
                </div>
            </div>
            <div class="col-4"></div>
            <div class="col-4">
                <div *ngFor="let item of [].constructor(getHalfImagesLength()); let i = index" class="d-flex flex-column px-2 my-4">
                    <div *ngIf="choosenImages[i]?.signedUrl" class="p-2 bg-white shadow-default" (click)="textToSpeechImage(choosenImages[i].description)">
                        <img alt="" [src]="choosenImages[i].signedUrl" class="w-100 h-100 border-radius-default" />
                    </div>
                    <div
                        *ngIf="!choosenImages[i]?.signedUrl"
                        id="answers-list"
                        class="p-2 bg-white shadow-default question-container"
                        [ngClass]="dropContainerVisible ? 'drop-container' : ''"
                        cdkDropList
                        (cdkDropListDropped)="imageChoosed($event, i)"
                    ></div>
                </div>
            </div>
        </div>
        <div *ngIf="availableImagesFiles.length == 1" (click)="textToSpeechImage(availableImagesFiles[0].description)">
            <img alt="" [src]="availableImagesFiles[0].signedUrl" class="w-100 h-100 border-radius-default" />
        </div>
    </div>
</ng-container>

<ng-template #modifingCreatingTemplate>
    <div class="p-3 bg-white border-radius-default shadow-default align-items-center justify-content-center" style="min-height: 30em">
        <div class="d-flex flex-column px-4 my-4">
            <p class="text-blue pb-2 text-bold h4">
                {{ 'Images' | localize }}
            </p>

            <div class="d-flex flex-grow-2">
                <content-answers-list
                    class="w-100 h-100 border-radius-default"
                    #contentAnswersList
                    [state]="state"
                    [answers]="availableImagesFiles"
                    [contentAllowed]="contentAllowed"
                >
                </content-answers-list>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-12 d-flex justify-content-end">
                <button type="button" class="btn btn-primary" *ngIf="!exerciseExtendedDto?.isDone" (click)="save()" [disabled]="!isExerciseValid()">{{ 'Save' | localize }}</button>
            </div>
        </div>
    </div>
</ng-template>
