<div
    class="d-flex flex-wrap align-items-start answers-drop-area bg-white p-2"
    style="justify-content: center"
    cdkDropList
    id="answer-list-dnd"
    (cdkDropListDropped)="drop($event)"
    [cdkDropListConnectedTo]="['question-list-dnd', 'library-list-dnd', 'rightAnswer-list-dnd']"
>
    <div class="answer-container d-flex flex-column p-3 mx-1 bg-white shadow-default" *ngFor="let answer of answers; let i = index">
        <div *ngIf="fileService.isTextContent(answer)">
            <textarea class="w-100 h-100" type="text" [(ngModel)]="answer.description" [disabled]="state == states.executing"> </textarea>
        </div>

        <div *ngIf="fileService.isImageFile(answer)" (click)="textToSpeechImage(answer.description)">
            <div class="answer-image-container p-2 mx-auto">
                <img
                    alt=""
                    [src]="answer.signedUrl"
                    [draggable]="false"
                    class="w-100 possible-answer-image border-radius-default"
                    [id]="answer.id"
                    [cdkDragData]="answer"
                    [cdkDragDisabled]="isDragDisabled(answer.id)"
                    (cdkDragStarted)="dragStarted(answer)"
                    (cdkDragEnded)="dragEnded()"
                    cdkDrag
                    cdkDragBoundary=".rendering-container"
                />
            </div>

            <div class="text-center mb-2">
                <div *ngIf="state == states.edit || state == states.create" class="label">
                    <span>
                        {{ 'CustomObjectAnswerLabel' | localize }}
                    </span>

                    <input type="text" class="form-control mt-2" [(ngModel)]="answer.description" />
                </div>

                <span *ngIf="state == states.executing">{{ answer.description }}</span>
            </div>
        </div>
        <div *ngIf="state == states.edit || state == states.create" class="text-center mb-2">
            <em class="d-inline-block fas fa-trash" (click)="removeAnswer(i)"></em>
        </div>
    </div>
</div>
