import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

// External libs
import { Observable, Subject, of } from 'rxjs';
import { map } from 'rxjs/operators';

// App Services

import { ContentAllowedConfiguration } from '@shared/models/content-configuration.model';
import { ContentPreview } from '@shared/models/file-info.model';
import { FileDto, FileExcelDto, FileExtendedDto, FileParameter, FileServiceProxy } from '@shared/service-proxies/service-proxies';

import * as FileSaver from 'file-saver';

@Injectable({
    providedIn: 'root',
})
export class FileService {
    public currentUploadStatus$: Subject<number> = new Subject();
    cachedFiles: ContentPreview[] = [];
    avatarChachedFiles: ContentPreview[] = [];
    constructor(private _fileService: FileServiceProxy, private sanitizer: DomSanitizer) {}

    public delete(id: number): Observable<void> {
        return this._fileService.delete(id);
    }

    public getAvatar(idAvatarFile): Observable<ContentPreview> {
        return this.getFile(idAvatarFile);
    }

    public getFile(file: FileDto, downloadVideo?: boolean): Observable<ContentPreview> {
        if (this.isVideoFile(file) && !downloadVideo) {
            const fileExtended = file as FileExtendedDto;
            return of(new ContentPreview(fileExtended, this.sanitizer));
        }
        let observable = new Observable<ContentPreview>();
        const fileCached = this.cachedFiles.find((el: ContentPreview) => el.id == file.id);
        if (fileCached) {
            observable = of(fileCached);
        } else {
            observable = this._fileService.getFile(file.id).pipe(
                map((response) => {
                    const file = new ContentPreview(response, this.sanitizer);
                    this.cachedFiles.push(file);
                    return file;
                })
            );
        }
        return observable;
    }

    getAll(tags: string[], fileType: string, fileName: string, skipCount: number, maxResultCount: number) {
        return this._fileService.getAll(tags, fileType, true, fileName, skipCount, maxResultCount);
    }

    public uploadFile(file: FileParameter, tag: string, isPrivate: boolean, activityId: number, isAvatar: boolean) {
        return this._fileService.uploadFile(file, tag, isPrivate, activityId, isAvatar);
    }

    public uploadAvatar(file: FileParameter) {
        return this.uploadFile(file, null, false, null, true);
    }

    createTags(file: FileDto) {
        return this._fileService.createTags(file.id, file.tags);
    }
    base64ToArrayBuffer(base64) {
        const binary_string = window.atob(base64);
        const len = binary_string.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    }
    isVideoFile(file: FileDto) {
        return file?.mimeType?.includes('video');
    }
    isAudioFile(file: FileDto) {
        return file?.mimeType?.includes('audio');
    }
    isImageFile(file: FileDto) {
        return file?.mimeType?.includes('image');
    }
    isPdfFile(file: FileDto) {
        return file?.mimeType?.includes('pdf');
    }
    isTextContent(content: ContentPreview) {
        return content.isText;
    }
    isContentAllowed(content: ContentPreview, contentAllowed: ContentAllowedConfiguration) {
        if (this.isVideoFile(content) && contentAllowed.videoAccepted) return true;
        if (this.isAudioFile(content) && contentAllowed.audioAccepted) return true;
        if (this.isImageFile(content) && contentAllowed.imageAccepted) return true;
        if (this.isPdfFile(content) && contentAllowed.pdfAccepted) return true;
        if (this.isTextContent(content) && contentAllowed.textAccepted) return true;
        return false;
    }

    saveExcel(res: FileExcelDto) {
        const byteCharacters = atob(res.fileData);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob: Blob = new Blob([byteArray], { type: res.mimeType });
        FileSaver.saveAs(blob, res.fileName);
    }
}
