import { CAABaseStructure } from './../base-caa.component';

export class Answer {
    isCorrect: boolean = false;
    description: string = '';
    isSelected : boolean = false;
}

export class QuizQuestion {
    title: string;
    answers: Answer[] = [];
}


export class QuizSnapShotModel {
    selectedIndex = 0;
    questions: QuizQuestion[] = [];

    public structureToEmptySnapShot(baseStructure: string): QuizQuestion[] {
        const obj: CAABaseStructure = JSON.parse(baseStructure);

        for (let i = 0; i < +obj.index; i++) {
            this.questions.push(new QuizQuestion());
            for (let j = 0; j < +obj.quiz; j++) {
                this.questions[i].answers.push(new Answer());
            }
        }
        return this.questions;
    }
}
