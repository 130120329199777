import { Component, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CAATypeDto, ExerciseDto, ModelCAADto } from '@shared/service-proxies/service-proxies';
import { RenderingState } from '@shared/models/enum-caa-state';
import { AppComponentBase } from '@shared/app-component-base';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditorComponent } from '@node_modules/@ckeditor/ckeditor5-angular';
import { ViewChild } from '@node_modules/@angular/core';

@Component({
    selector: 'synthesis-caa',
    templateUrl: './caa-synthesis.component.html',
    styleUrls: ['./caa-synthesis.component.css'],
})
export class CaaSynthesisComponent extends AppComponentBase implements OnInit, OnChanges {
    @Input() modelCaa: ModelCAADto = new ModelCAADto();
    @Input() exerciseExtendedDto: ExerciseDto;
    @Input() caaType: CAATypeDto;
    @Input() state: RenderingState;
    @Input() correcting: boolean = false;

    @Output() saveEvent: EventEmitter<any> = new EventEmitter<any>();
    @Output() notifyCorrectAnswerEvent: EventEmitter<string> = new EventEmitter<string>();

    states = RenderingState;
    snapshotObj: SynthesisSnapshotModel = new SynthesisSnapshotModel();

    public Editor = Editor;

    @ViewChild(CKEditorComponent, { static: false }) ckEditor: CKEditorComponent;

    constructor(private injector: Injector) {
        super(injector);
    }

    ngOnInit(): void {}

    ngOnChanges(changes: SimpleChanges) {
        switch (this.state) {
            default:
                this.snapshotObj = { ...JSON.parse(this.modelCaa.excerciseSnapshotJson), ...JSON.parse(this.exerciseExtendedDto?.executedJson) } as SynthesisSnapshotModel;
                break;

            case RenderingState.edit:
                this.snapshotObj = JSON.parse(this.modelCaa.excerciseSnapshotJson) as SynthesisSnapshotModel;
                break;

            case RenderingState.create:
                break;
        }
    }

    saveFn() {
        this.modelCaa.excerciseSnapshotJson = JSON.stringify(this.snapshotObj);
        this.saveEvent.emit(this.modelCaa);
    }

    saveAnswer() {
        this.notifyCorrectAnswerEvent.emit(JSON.stringify(this.snapshotObj));
    }

    preventWritingIfExecuting(event: KeyboardEvent) {
        if (this.disabledWritingText || this.correcting) {
            event.preventDefault();
            event.stopPropagation();
        }
    }

    get textLength(): number {
        const text = new DOMParser().parseFromString(this.snapshotObj?.question, 'text/html')?.body?.textContent?.trim();
        return Math.floor((text?.length * this.snapshotObj.percentage) / 100) || 0;
    }

    get disabledWritingText(): boolean {
        return this.state === RenderingState.executing || !this.isGranted(this.permissionConsts.Exercise_Update);
    }

    highlight(text: string) {
        this.snapshotObj.question = text;
        this.saveAnswer();
    }
}

export class SynthesisSnapshotModel {
    question: string = '';
    answer: string = '';
    percentage: number;
}
