import { Component, EventEmitter, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { ContentPreview } from '@shared/models/file-info.model';
import { FileAnswer } from '@app/modelbasecaa/common-caa/file-answer';
import { ReconstructSequenceCaaComponent } from '@app/modelbasecaa/reconstruct-sequence-caa/reconstruct-sequence-caa.component';

@Component({
    selector: 'advanced-sequence-caa',
    templateUrl: './advanced-sequence-caa.component.html',
    styleUrls: ['./advanced-sequence-caa.component.css'],
})
export class AdvancedSequenceCaaComponent extends ReconstructSequenceCaaComponent implements OnDestroy, OnChanges, OnInit {
    @Output() notifyWrongAnswerEvent: EventEmitter<number> = new EventEmitter<number>();

    enabledId: number;

    populateAnswers() {
        super.populateAnswers();
        this.setEnabledId();
    }

    setEnabledId() {
        const firstAnswer: FileAnswer = this.snapShotObj?.sequence?.rightAnswersOrdered?.find(
            (answer: FileAnswer) => !!this.answersFiles.find((file: ContentPreview) => file?.id === answer?.fileId)
        );
        if (firstAnswer) {
            this.enabledId = firstAnswer.fileId;
        }
    }

    dropAnswerOnQuestion(event, index) {
        if (event.isPointerOverContainer) {
            let droppedFile: ContentPreview = event.item.data;
            if (droppedFile.id == this.snapShotObj.sequence.rightAnswersOrdered[index].fileId && this.canAnswer(index)) {
                this.snapShotObj.sequence.rightAnswersOrdered[index].isHidden = true;
                this.rightAnswersOrderedFiles[index] = droppedFile;
                this.hideCorrectAnswerFromAvailable(droppedFile);
                this.setEnabledId();
                this.notifyCorrectAnswerEvent.emit(JSON.stringify(this.snapShotObj));
            } else {
                this.notifyWrongAnswerEvent.emit(this.exerciseExtendedDto.id);
            }
        }
    }
}
