import { Injectable, OnDestroy, EventEmitter } from '@angular/core';

import { ReplaySubject, Subscription, Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { HubConnection } from '@microsoft/signalr';

import { TutoringCallCreateDto, TutoringCallDto, UserStatusCallsDto } from '@shared/service-proxies/service-proxies';
import { SignalRAspNetCoreHelper } from '@shared/helpers/SignalRAspNetCoreHelper';
import { HubConnectionStatus } from '@shared/hub-connection-status';

@Injectable({
    providedIn: 'root',
})
export class TutoringHubService implements OnDestroy {
    private static hubName = 'tutoring';
    public get connectionStatus$() {
        return this.status$.asObservable().pipe(distinctUntilChanged((prev, curr) => prev === curr));
    }

    private get tutoringHub() {
        // tslint:disable-next-line: no-string-literal
        return abp.signalr.hubs[TutoringHubService.hubName];
    }

    public onCallSent = new EventEmitter<TutoringCallDto>();
    public onIncomingCall = new EventEmitter<TutoringCallDto>();
    public onCallAccepted = new EventEmitter<TutoringCallDto>();
    public currentCall$ = new ReplaySubject<TutoringCallDto>(1);
    public onCallDeclined = new EventEmitter<TutoringCallDto>();
    public onCallCancelled = new EventEmitter<TutoringCallDto>();
    public onCallClosed = new EventEmitter<TutoringCallDto>();

    public userStatusCalls$ = new Subject<UserStatusCallsDto[]>();
    public onUserStatusChanged = new EventEmitter();

    private status$ = new ReplaySubject<HubConnectionStatus>(1);

    private subscription = new Subscription();

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public startConnection = () => {
        function configureConnection(connection: HubConnection, context: TutoringHubService) {
            // tslint:disable-next-line: no-string-literal
            abp.signalr.hubs[TutoringHubService.hubName] = connection;

            let tries = 1;
            let reconnectTime = SignalRAspNetCoreHelper.reconnectTime;

            // Reconnect loop
            function tryReconnect() {
                if (tries <= SignalRAspNetCoreHelper.maxTries) {
                    context.status$.next('reconnecting');

                    connection
                        .start()
                        .then(() => {
                            reconnectTime = SignalRAspNetCoreHelper.reconnectTime;
                            tries = 1;
                            console.log('Reconnected to TutoringHub SignalR!');

                            context.status$.next('connected');
                        })
                        .catch(() => {
                            tries += 1;
                            reconnectTime = SignalRAspNetCoreHelper.increaseReconnectTime(reconnectTime);
                            setTimeout(() => {
                                tryReconnect();
                            }, reconnectTime);
                        });
                }
            }

            // Reconnect if hub disconnects
            connection.onclose((e) => {
                context.status$.next('disconnected');

                if (e) {
                    abp.log.debug('Connection closed with error: ' + e);
                } else {
                    abp.log.debug('Disconnected');
                }

                if (!SignalRAspNetCoreHelper.autoReconnect) {
                    return;
                }

                tryReconnect();
            });

            context.status$.next('connected');

            context.addConnectionStatusListener();
            context.refreshUserStatusListener();
            context.addUserStatusChangedListener();
            context.addCallSentListener();
            context.addIncomingCallListener();
            context.addRecipientAnswersListener();
            context.addSenderCancellingListener();
            context.addClosedCallListener();
        }

        const sub = SignalRAspNetCoreHelper.signalRLoaded.subscribe((loaded) => {
            if (loaded) {
                this.subscription?.unsubscribe();
                this.subscription = new Subscription();

                abp.signalr
                    .startConnection(abp.appPath + 'signalr-tutoringhub', () => {})
                    .then((hub) => {
                        abp.log.debug('Connected to TutoringHub server through TutoringHubService!');

                        configureConnection(hub, this);
                    });
            }
        });
        this.subscription.add(sub);
    };

    private addConnectionStatusListener = () => {
        this.tutoringHub.on(`disconnected`, (obj: any) => {
            console.log('TutoringHubService disconnected: ', obj);
            this.status$.next('disconnected');
        });

        this.tutoringHub.on(`connected`, (obj: any) => {
            console.log('TutoringHubService connected: ', obj);
            this.status$.next('connected');
        });

        this.tutoringHub.on(`reconnecting`, (obj: any) => {
            console.log('TutoringHubService reconnecting: ', obj);
            this.status$.next('reconnecting');
        });
    };

    private refreshUserStatusListener = () => {
        this.tutoringHub.on(`sendUserStatusCalls`, (list: UserStatusCallsDto[]) => {
            console.log('TutoringHubService New Event!', 'sendUserStatusCalls: ', list);
            this.userStatusCalls$.next(list.map((u) => new UserStatusCallsDto(u)));
        });
    };

    private addUserStatusChangedListener = () => {
        this.tutoringHub.on(`userStatusChanged`, (obj: any) => {
            console.log('TutoringHubService New Event!', 'userStatusChanged: ', obj);
            this.onUserStatusChanged.emit();
        });
    };

    askUserStatusCalls(): void {
        console.log('TutoringHubService askUserStatusCalls');
        this.tutoringHub.invoke('AskUserStatusCalls');
    }

    changeUserStatusCalls(statusId: number): Promise<any> {
        console.log('TutoringHubService changeUserStatusCalls: ', statusId);
        return this.tutoringHub.invoke('ChangeUserStatusCalls', statusId);
    }

    startCall(partecipantId: number): void {
        if (partecipantId) {
            const call = new TutoringCallCreateDto();
            call.partecipantId = partecipantId;
            call.answered = false;
            call.elapsedTime = null;

            console.log('TutoringHubService startCall: ', call);
            this.tutoringHub.invoke('sendCall', call);
        }
    }

    private addCallSentListener = () => {
        this.tutoringHub.on(`callSent`, (call: TutoringCallDto) => {
            console.log('TutoringHubService onCallSent: ', call);

            this.onCallSent.emit(call);
        });
    };

    private addIncomingCallListener = () => {
        this.tutoringHub.on(`onIncomingCall`, (call: TutoringCallDto) => {
            this.onIncomingCall.emit(call);
            console.log('TutoringHubService onIncomingCall: ', call);
        });
    };

    acceptCall(call: TutoringCallDto): Promise<void> {
        return this.tutoringHub.invoke('acceptCall', call);
    }

    declineCall(call: TutoringCallDto): Promise<void> {
        return this.tutoringHub.invoke('declineCall', call);
    }

    private addRecipientAnswersListener = () => {
        this.tutoringHub.on('onCallAccepted', (call: TutoringCallDto) => {
            this.onCallAccepted.emit(call);
        });

        this.tutoringHub.on('onCallDeclined', (call: TutoringCallDto) => {
            this.onCallDeclined.emit();
        });
    };

    cancelCall(call: TutoringCallDto): Promise<void> {
        return this.tutoringHub.invoke('cancelCall', call);
    }

    private addSenderCancellingListener = () => {
        this.tutoringHub.on('onCallCancelled', (call: TutoringCallDto) => {
            this.onCallCancelled.emit(call);
        });
    };

    private addClosedCallListener = () => {
        this.tutoringHub.on('onCallClosed', (call: TutoringCallDto) => {
            this.onCallClosed.emit(call);
        });
    };

    closeCall(call: TutoringCallDto): Promise<void> {
        return this.tutoringHub.invoke('closeCall', call);
    }
}
