<ng-container *ngIf="state === states.executing; else modifingCreatingTemplate">
    <div class="p-3 bg-white border-radius-default shadow-default" style="min-height: 30em">
        <ng-container [ngSwitch]="typeFile">
            <img
                *ngSwitchCase="'image'"
                [src]="contentFile?.signedUrl ? contentFile?.signedUrl : '/assets/img/image-placeholder-exercise.png'"
                id="content-list-dnd"
                class="img-fluid"
            />
            <audio controls *ngSwitchCase="'audio'" [src]="contentFile?.signedUrl" type="audio/mpeg"></audio>
            <pdf-viewer
                class="w-100 h-100"
                *ngSwitchCase="'pdf'"
                [src]="contentFile?.signedUrl"
                [original-size]="true"
                [fit-to-page]="true"
                [render-text]="true"
                style="display: block"
            ></pdf-viewer>
            <video class="w-100 h-100" *ngSwitchCase="'video'" [src]="contentFile.signedUrl" muted controls></video>
        </ng-container>
    </div>
</ng-container>
<ng-template #modifingCreatingTemplate>
    <div class="d-flex flex-row align-items-start justify-content-center">
        <div class="d-flex flex-column px-2 mb-4 w-100 h-100">
            <p class="text-darkblue h4 pb-2">
                {{ 'Content' | localize }}
            </p>
            <div class="p-2 bg-white shadow-default border-radius-default" style="min-height: 30em" cdkDropList (cdkDropListDropped)="droppedContent($event)" cdkDropListConnectedTo="library-list-dnd">
                <ng-container [ngSwitch]="typeFile">
                    <img
                        *ngSwitchCase="'image'"
                        [src]="contentFile?.signedUrl ? contentFile?.signedUrl : '/assets/img/image-placeholder-exercise.png'"
                        id="content-list-dnd"
                        class="img-fluid"
                    />
                    <audio controls *ngSwitchCase="'audio'" [src]="contentFile?.signedUrl" type="audio/mpeg"></audio>
                    <pdf-viewer
                        class="w-100 h-100"
                        *ngSwitchCase="'pdf'"
                        [src]="contentFile?.signedUrl"
                        [original-size]="true"
                        [fit-to-page]="true"
                        [render-text]="true"
                        style="display: block"
                    ></pdf-viewer>
                    <video class="w-100 h-100" *ngSwitchCase="'video'" [src]="contentFile.signedUrl" muted controls></video>
                </ng-container>
            </div>
        </div>
    </div>
    <button type="button" class="btn btn-primary" (click)="save()">{{ 'Save' | localize }}</button>
</ng-template>
