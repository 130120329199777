import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AbpHttpInterceptor } from 'abp-ng2-module';

import { TutoringHubService } from '@shared/services/tutoring-hub.service';
import { VideoChatService } from '@shared/services/videochat.service';
import { StorageService } from '@shared/services/storage.service';
import { DeviceService } from '@shared/services/device.service';

import * as ApiServiceProxies from './service-proxies';
import { TextToSpeechService } from '@shared/services/text-to-speech.service';

@NgModule({
    providers: [
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.ConfigurationServiceProxy,
        ApiServiceProxies.CenterServiceProxy,
        ApiServiceProxies.CenterAddressServiceProxy,
        ApiServiceProxies.CenterAdministratorServiceProxy,
        ApiServiceProxies.CenterOperatorServiceProxy,
        ApiServiceProxies.MunicipalityServiceProxy,
        ApiServiceProxies.ActivityServiceProxy,
        ApiServiceProxies.ActivityClientServiceProxy,
        ApiServiceProxies.ClientOperatorServiceProxy,
        ApiServiceProxies.FileServiceProxy,
        ApiServiceProxies.CAATypeServiceProxy,
        ApiServiceProxies.ModelCAAServiceProxy,
        ApiServiceProxies.ExerciseServiceProxy,
        ApiServiceProxies.AsphiClockServiceProxy,
        ApiServiceProxies.RelativeUserServiceProxy,
        ApiServiceProxies.UserTitleServiceProxy,
        ApiServiceProxies.TitleServiceProxy,
        ApiServiceProxies.TutoringCallServiceProxy,
        ApiServiceProxies.UserStatusCallsServiceProxy,
        ApiServiceProxies.TwilioVideoServiceProxy,
        ApiServiceProxies.ApiServiceProxy,
        ApiServiceProxies.NoteServiceProxy,
        TutoringHubService,
        VideoChatService,
        StorageService,
        DeviceService,
        TextToSpeechService,
        { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true },
    ],
})
export class ServiceProxyModule {}
