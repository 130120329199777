export class FileAnswer {
    description?: string;
    fileId: number;
    isHidden: boolean
    constructor(fileId, isHidden, description?){
        this.fileId = fileId;
        this.description = description;
        this.isHidden = isHidden;
    }
}
