<ng-container *ngIf="state === states.executing; else modifingCreatingTemplate">
    <mat-vertical-stepper class="exercise-questions-wrapper bg-transparent" #stepper [(selectedIndex)]="stepperIndex">
        <mat-step *ngFor="let question of snapShotObj.questions; let i = index" [editable]="!exerciseExtendedDto?.isDone" [completed]="stepCompleted[i]">
            <ng-template matStepLabel>
                <p class="text-heading h5 text-blue">{{ question.title }}</p>
            </ng-template>
            <!--mat-label>{{question.title}}</!--mat-label-->
            <mat-radio-group class="d-flex flex-column align-items-start mt-2 mb-3">
                <mat-radio-button
                    class="text-darkblue mb-3"
                    #elemRadio
                    [disabled]="stepCompleted[i] || exerciseExtendedDto?.isDone"
                    *ngFor="let answer of question.answers; let j = index"
                    [checked]="answer.isSelected"
                    [value]="answer.description"
                    (change)="choosedAnswer(i, j, elemRadio, stepper)"
                    >{{ answer.description }}</mat-radio-button
                >
            </mat-radio-group>
        </mat-step>

        <mat-step>
            <ng-template matStepLabel>
                <em class="fas fa-flag-checkered text-dark-blue d-inline-block mr-2"></em>
            </ng-template>

            <p class="d-none text-dark-blue d-inline-block mr-2 text-bold text-uppercase">{{ 'Congratulations' | localize }} {{ 'ExerciseDone' | localize }}</p>
        </mat-step>
    </mat-vertical-stepper>
</ng-container>

<ng-template #modifingCreatingTemplate>
    <div class="d-flex flex-column quiz-caa-question-container pb-4 mb-4" *ngFor="let question of snapShotObj.questions; let i = index">
        <p class="w-100">{{ 'QuizCAAQuestionCounter' | localize }} {{ i + 1 }}:</p>

        <div class="w-100 input-default mb-4">
            <span class="text-bold text-grey text-uppercase d-block mb-2">{{ 'QuizCAALabelQuestion' | localize }}</span>
            <input type="text" [disabled]="exerciseExtendedDto?.isDone" [name]="'question-title-' + i" class="form-control shadow-default bg-white" [(ngModel)]="question.title" />
        </div>

        <div class="d-flex flex-column justify-content-between answers-container">
            <div class="custom-control custom-radio d-flex align-items-center justify-content-between answer-container" *ngFor="let answer of question.answers; let j = index">
                <div class="d-flex flex-row w-100 justify-content-between mb-2">
                    <div class="col-8">
                        <span class="text-smaller text-grey text-uppercase d-block mb-1">{{ 'QuizCAAQuestionAnswer' | localize }} {{ j + 1 }}:</span>
                        <input
                            type="text"
                            [disabled]="exerciseExtendedDto?.isDone"
                            [name]="'question-description-' + i + '-' + j"
                            class="form-control shadow-default"
                            [ngClass]="exerciseExtendedDto?.isDone && answer?.isSelected ? 'bg-warning' : 'bg-white'"
                            [(ngModel)]="answer.description"
                        />
                    </div>

                    <div class="col-4 d-flex align-items-center custom-control custom-radio">
                        <input
                            type="radio"
                            [id]="'question-' + i + '-answer-' + j"
                            [name]="'question-' + i"
                            class="custom-control-input"
                            [value]="answer.description"
                            [checked]="answer.isCorrect"
                            [disabled]="exerciseExtendedDto?.isDone"
                            [color]="'primary'"
                            (change)="choosedRightAnswer(i, j)"
                        />

                        <label class="custom-control-label" [for]="'question-' + i + '-answer-' + j">{{ 'QuizCAARightAnswerFlag' | localize }}</label>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <button type="button" class="btn btn-primary" *ngIf="!exerciseExtendedDto?.isDone" (click)="save()">{{ 'Save' | localize }}</button>
</ng-template>
