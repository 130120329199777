import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'formatTime',
})
export class FormatTimePipe implements PipeTransform {
    public transform(value: number, withSeconds?: boolean): string {
        const seconds = value >= 0 ? value : 0;
        if (withSeconds) {
            return new Date(seconds * 1000).toISOString().substr(11, 8);
        }
        return new Date(seconds * 1000).toISOString().substr(11, 5);
    }
}
