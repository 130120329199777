import { Component, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ClassificationComponentBase } from '@shared/classification-component-base';
import { ContentAllowedConfiguration } from '@shared/models/content-configuration.model';
import { RenderingState } from '@shared/models/enum-caa-state';
import { ContentPreview } from '@shared/models/file-info.model';
import { CAATypeDto, ExerciseDto, ModelCAADto } from '@shared/service-proxies/service-proxies';
import { FileAnswer } from '../common-caa/file-answer';
import { ClassificationSnapshotModel } from './classification-snapshot.model';

@Component({
    selector: 'vertical-classification-caa',
    templateUrl: './vertical-classification-caa.component.html',
    styleUrls: ['./vertical-classification-caa.component.css'],
})
export class VerticalClassificationCaaComponent extends ClassificationComponentBase implements OnChanges, OnInit {
    @Input() caaType: CAATypeDto;
    @Input() state: RenderingState;
    @Input() modelCaa: ModelCAADto;
    @Input() exerciseExtendedDto: ExerciseDto;
    @Output() saveEvent: EventEmitter<ModelCAADto> = new EventEmitter<ModelCAADto>();
    @Output() notifyCorrectAnswerEvent: EventEmitter<string> = new EventEmitter<string>();
    @Output() notifyExerciseCompletedEvent: EventEmitter<string> = new EventEmitter<string>();
    contentAllowed: ContentAllowedConfiguration = new ContentAllowedConfiguration(true);

    constructor(private injector: Injector) {
        super(injector);
    }

    ngOnInit() {}

    ngOnChanges(changes: SimpleChanges): void {
        switch (this.state) {
            case RenderingState.create:
                {
                    this.snapShotObj = new ClassificationSnapshotModel();
                }
                break;
            case RenderingState.edit:
                {
                    this.snapShotObj = JSON.parse(this.modelCaa.excerciseSnapshotJson);
                    this.populateQuestions();
                    this.populateAnswers();
                }
                break;
            case RenderingState.executing:
                {
                    this.snapShotObj =
                        this.exerciseExtendedDto.executedJson != null ? JSON.parse(this.exerciseExtendedDto.executedJson) : JSON.parse(this.modelCaa.excerciseSnapshotJson);
                    this.populateQuestions();
                    this.populateAnswers();
                }
                break;
        }
        if (this.exerciseExtendedDto?.isDone) {
            this.snapShotObj = JSON.parse(this.exerciseExtendedDto.executedJson);
        }
        this.getImages();
    }

    dropAnswerOnQuestion(event, rightAnswersIdArray) {
        if (event.isPointerOverContainer) {
            let droppedFile: ContentPreview = event.item.data;
            const index = rightAnswersIdArray.findIndex((el) => el == droppedFile.id);
            if (index != -1) {
                this.showRightAnswer(droppedFile, droppedFile.description);
                this.hideCorrectAnswerFromAvailable(droppedFile);
            }
        }
    }

    hideCorrectAnswerFromAvailable(droppedFile) {
        const removingIndex = this.answers.indexOf(droppedFile);
        this.answers.splice(removingIndex, 1);
        const hidingIndex = this.snapShotObj.classification.classificationAnswers.findIndex((x) => x.fileId === droppedFile.id);
        this.snapShotObj.classification.classificationAnswers[hidingIndex].isHidden = true;
        this.notifyCorrectAnswerEvent.emit(JSON.stringify(this.snapShotObj));
    }

    save() {
        this.snapShotObj.classification.classificationAnswers = [];
        this.answers.forEach((answer: ContentPreview) => {
            this.snapShotObj.classification.classificationAnswers.push(new FileAnswer(answer.id, false, answer.description));
        });
        this.modelCaa.excerciseSnapshotJson = JSON.stringify(this.snapShotObj);
        this.saveEvent.emit(this.modelCaa);
    }
}
