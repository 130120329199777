<div class="modal-header py-1 d-flex flex-row align-items-center">

    <!-- TITOLO MODALE -->
    <h4 class="modal-title text-darkblue text-heading mb-0">{{ title }}</h4>

    <!-- TASTO CHIUDI MODALE -->
    <button type="button" class="close modal-close-button" aria-label="Close" (click)="onCloseClick.emit()" *ngIf="closeButtonEnabled">
        <span aria-hidden="true">&times;</span>
    </button>

</div>
