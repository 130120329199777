import { AfterViewInit, Component, EventEmitter, Injector, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DxDiagramComponent } from '@node_modules/devextreme-angular';
import { CAATypeDto, ExerciseDto, ModelCAADto } from '@shared/service-proxies/service-proxies';
import { RenderingState } from '@shared/models/enum-caa-state';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { loadMessages, locale } from 'devextreme/localization';
import itMessages from '@app/devextreme-it.json';
import { AppComponentBase } from '@shared/app-component-base';
import { debounceTime } from 'rxjs/operators';
import DevExpress from '@node_modules/devextreme';
import { RequestEditOperationEvent } from '@node_modules/devextreme/ui/diagram';
import SelectionChangedEvent = DevExpress.ui.dxDiagram.SelectionChangedEvent;

const TIMER = 700;

@Component({
    selector: 'app-map-caa',
    templateUrl: './map-caa.component.html',
    styleUrls: ['./map-caa.component.css'],
})
export class MapCaaComponent extends AppComponentBase implements OnInit, AfterViewInit, OnChanges, OnDestroy {
    @ViewChild('map-caa') test: any;
    @ViewChild(DxDiagramComponent, { static: false }) diagram: DxDiagramComponent;

    @Input() caaType: CAATypeDto;
    @Input('modelCaa') modelCaaInput: ModelCAADto;
    @Input() state: RenderingState;
    @Input() exerciseExtendedDto: ExerciseDto;
    @Input() correcting: boolean;

    @Output() saveEvent: EventEmitter<ModelCAADto> = new EventEmitter<ModelCAADto>();
    @Output() notifyCorrectAnswerEvent = new EventEmitter<any>();

    structure: string;

    modelCaa$: BehaviorSubject<ModelCAADto> = new BehaviorSubject<ModelCAADto>(null);

    subscription: Subscription = new Subscription();
    isLoading: boolean = false;

    constructor(private injector: Injector) {
        super(injector);
        loadMessages(itMessages);
        locale(this.localization.currentLanguage.name);
    }

    ngOnInit(): void {}

    ngOnDestroy() {}

    ngOnChanges(changes: SimpleChanges) {
        this.waitResponse();
    }

    setBehaviorSubject() {
        if (this.modelCaaInput) {
            return this.modelCaa$.next(this.modelCaaInput);
        }
    }

    async waitResponse() {
        await this.setBehaviorSubject();
        this.isLoading = false;
    }

    ngAfterViewInit() {
        this.isLoading = false;
        combineLatest([this.diagram.onSelectionChanged, this.diagram.onRequestEditOperation])
            .pipe(debounceTime(TIMER))
            .subscribe((events: [SelectionChangedEvent, RequestEditOperationEvent]) => this.getStructure(events));
        this.subscription.add(
            this.modelCaa$.subscribe((modelCaa: ModelCAADto) => {
                if (this.exerciseExtendedDto?.isDone || (this.state === RenderingState.executing && this.exerciseExtendedDto.executedJson)) {
                    this.diagram.instance.import(this.exerciseExtendedDto.executedJson);
                } else {
                    if (this.modelCaaInput?.excerciseSnapshotJson) {
                        this.diagram.instance.import(modelCaa?.excerciseSnapshotJson);
                    }
                }
            })
        );
    }

    get isEditing(): boolean {
        return [RenderingState.edit, RenderingState.create].includes(this.state);
    }

    getStructure(events: [SelectionChangedEvent, RequestEditOperationEvent]) {
        this.structure = this.diagram.instance.export();
        if (this.state === RenderingState.executing && this.structure !== this.exerciseExtendedDto.executedJson) {
            this.exerciseExtendedDto.executedJson = this.structure;
            this.notifyCorrectAnswerEvent.emit(this.exerciseExtendedDto.executedJson);
            this.isLoading = true;
        }
    }

    save() {
        this.modelCaaInput.excerciseSnapshotJson = this.diagram.instance.export();
        this.modelCaaInput.caaType = this.caaType;
        this.saveEvent.emit(this.modelCaaInput);
    }
}
