import { Injector, ElementRef, Renderer2 } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { LocalizationService, PermissionCheckerService, FeatureCheckerService, NotifyService, SettingService, MessageService, AbpMultiTenancyService } from 'abp-ng2-module';

import { AppSessionService } from '@shared/session/app-session.service';
import { AppConsts } from '@shared/AppConsts';
import { TextToSpeechService } from './services/text-to-speech.service';
import { AppPermissions } from '@shared/AppPermissions';

export interface IFormValidationBase {
    form: UntypedFormGroup;
}

export abstract class AppComponentBase implements IFormValidationBase {
    localizationSourceName = AppConsts.localization.defaultLocalizationSourceName;
    maxFileSize = 1610612736;

    permissionConsts = AppPermissions;

    form: UntypedFormGroup;
    localization: LocalizationService;
    permission: PermissionCheckerService;
    feature: FeatureCheckerService;
    notify: NotifyService;
    setting: SettingService;
    message: MessageService;
    multiTenancy: AbpMultiTenancyService;
    appSession: AppSessionService;
    elementRef: ElementRef;
    textToSpeechService: TextToSpeechService;
    renderer: Renderer2;
    get controls() {
        return this.form?.controls;
    }

    constructor(injector: Injector) {
        this.localization = injector.get(LocalizationService);
        this.permission = injector.get(PermissionCheckerService);
        this.feature = injector.get(FeatureCheckerService);
        this.notify = injector.get(NotifyService);
        this.setting = injector.get(SettingService);
        this.message = injector.get(MessageService);
        this.multiTenancy = injector.get(AbpMultiTenancyService);
        this.appSession = injector.get(AppSessionService);
        this.elementRef = injector.get(ElementRef);
        this.textToSpeechService = injector.get(TextToSpeechService);
        this.renderer = injector.get(Renderer2);
    }

    l(key: string, ...args: any[]): string {
        let localizedText = this.localization.localize(key, this.localizationSourceName);

        if (!localizedText) {
            localizedText = key;
        }

        if (!args || !args.length) {
            return localizedText;
        }

        args.unshift(localizedText);
        return abp.utils.formatString.apply(this, args);
    }

    startLoading() {
        abp.ui.setBusy(document.body);
        this.renderer.addClass(document.body, 'overflow-hidden');
    }

    stopLoading() {
        abp.ui.clearBusy(document.body);
        this.renderer.removeClass(document.body, 'overflow-hidden');
    }

    isGranted(permissionName: string): boolean {
        return this.permission.isGranted(permissionName);
    }

    isInRole(role: string) {
        return this.appSession.user.roles.some((r) => r === role);
    }

    isAdmin(): boolean {
        return this.isInRole(AppConsts.roles.Admin);
    }

    isAdminCenter(): boolean {
        return this.isInRole(AppConsts.roles.AdminCenter);
    }

    isOperator(): boolean {
        return this.isInRole(AppConsts.roles.Operator);
    }

    isClient(): boolean {
        return this.isInRole(AppConsts.roles.Client);
    }

    isRelative(): boolean {
        return this.isInRole(AppConsts.roles.Relative);
    }
    textToSpeechImage(text: string) {
        this.textToSpeechService.speak(text);
    }
}
