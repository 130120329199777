import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'snack-caa',
    templateUrl: './snack-caa.component.html',
    styleUrls: ['./snack-caa.component.css'],
})
export class SnackCaaComponent {
    constructor() {}
}
