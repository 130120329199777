<ng-container *ngIf="state === states.executing; else modifingCreatingTemplate">
    <div class="backgroung-container">
        <p class="text-darkblue text-center px-2 mb-2 pt-4">{{ 'ClassificationInstruction' | localize }}</p>
        <div class="d-flex flex-row align-items-start justify-content-center" cdkDropListGroup>
            <div class="d-flex flex-column px-4 horizontal-classification-container">
                <div class="text-heading text-blue text-center mb-2">
                    <h4>{{ question1File?.description }}</h4>
                </div>
                <div class="d-flex flex-row shadow-default question-container">
                    <div class="d-flex flex-column justify-content-center">
                        <img
                            [alt]="question1File?.description"
                            [src]="question1File?.signedUrl ? question1File?.signedUrl : '/assets/img/image-placeholder-exercise.png'"
                            (click)="textToSpeechImage(question1File?.description)"
                            class="w-100 h-100 border-radius-default"
                        />
                    </div>
                </div>

                <div *ngFor="let item of [].constructor(RIGHT_ANSWERS_NUMBER); let i = index; let even = even">
                    <div class="d-flex flex-row align-items-start" *ngIf="even">
                        <div
                            *ngIf="!question1AnswersFiles[i]?.signedUrl"
                            class="d-flex flex-column p-1 mb-2 bg-white shadow-default answer-container"
                            [ngClass]="dropContainerVisible ? 'drop-container' : ''"
                            (cdkDropListDropped)="dropAnswerOnQuestion($event, snapShotObj.classification.question1RightAnswersId)"
                            cdkDropList
                        ></div>

                        <div *ngIf="question1AnswersFiles[i]?.signedUrl" class="p-2 bg-white shadow-default answer-container">
                            <img
                                [alt]="question1AnswersFiles[i]?.description"
                                [src]="question1AnswersFiles[i]?.signedUrl"
                                class="answer"
                                (click)="textToSpeechImage(question1AnswersFiles[i]?.description)"
                            />
                            <div class="text-center my-1">
                                <span *ngIf="question1AnswersFiles[i] != null" class="answer-container-text">{{ question1AnswersFiles[i].description }}</span>
                            </div>
                        </div>
                        <div
                            *ngIf="!question1AnswersFiles[i + 1]?.signedUrl"
                            class="d-flex flex-column p-1 mb-2 bg-white shadow-default answer-container"
                            [ngClass]="dropContainerVisible ? 'drop-container' : ''"
                            cdkDropList
                            (cdkDropListDropped)="dropAnswerOnQuestion($event, snapShotObj.classification.question1RightAnswersId)"
                        ></div>

                        <div *ngIf="question1AnswersFiles[i + 1]?.signedUrl" class="p-2 bg-white shadow-default answer-container">
                            <img
                                [alt]="question1AnswersFiles[i + 1]?.description"
                                [src]="question1AnswersFiles[i + 1]?.signedUrl"
                                class="answer"
                                (click)="textToSpeechImage(question1AnswersFiles[i + 1]?.description)"
                            />
                            <div class="text-center my-1">
                                <span *ngIf="question1AnswersFiles[i + 1] != null" class="answer-container-text">{{ question1AnswersFiles[i + 1].description }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--seconda colonna-->

            <div class="d-flex flex-column px-4 horizontal-classification-container">
                <div class="text-heading text-blue text-center mb-2">
                    <h4>{{ question2File?.description }}</h4>
                </div>
                <div class="d-flex flex-row shadow-default question-container">
                    <div class="d-flex flex-column justify-content-center">
                        <img
                            [alt]="question2File?.description"
                            [src]="question2File?.signedUrl ? question2File?.signedUrl : '/assets/img/image-placeholder-exercise.png'"
                            (click)="textToSpeechImage(question2File?.description)"
                            class="w-100 h-100 border-radius-default"
                        />
                    </div>
                </div>

                <div *ngFor="let item of [].constructor(RIGHT_ANSWERS_NUMBER); let i = index; let even = even">
                    <div class="d-flex flex-row align-items-start" *ngIf="even">
                        <div
                            *ngIf="!question2AnswersFiles[i]?.signedUrl"
                            class="d-flex flex-column p-2 mb-2 bg-white shadow-default answer-container"
                            [ngClass]="dropContainerVisible ? 'drop-container' : ''"
                            cdkDropList
                            (cdkDropListDropped)="dropAnswerOnQuestion($event, snapShotObj.classification.question2RightAnswersId)"
                        ></div>

                        <div *ngIf="question2AnswersFiles[i]?.signedUrl" class="p-2 bg-white shadow-default answer-container">
                            <img
                                [alt]="question2AnswersFiles[i]?.description"
                                [src]="question2AnswersFiles[i]?.signedUrl"
                                class="answer"
                                (click)="textToSpeechImage(question2AnswersFiles[i]?.description)"
                            />
                            <div class="text-center my-1">
                                <span *ngIf="question2AnswersFiles[i] != null" class="answer-container-text">{{ question2AnswersFiles[i].description }}</span>
                            </div>
                        </div>

                        <div
                            *ngIf="!question2AnswersFiles[i + 1]?.signedUrl"
                            class="d-flex flex-column p-2 mb-2 bg-white shadow-default answer-container"
                            [ngClass]="dropContainerVisible ? 'drop-container' : ''"
                            cdkDropList
                            (cdkDropListDropped)="dropAnswerOnQuestion($event, snapShotObj.classification.question2RightAnswersId)"
                        ></div>

                        <div *ngIf="question2AnswersFiles[i + 1]?.signedUrl" class="p-2 bg-white shadow-default answer-container">
                            <img
                                [alt]="question2AnswersFiles[i + 1]?.description"
                                [src]="question2AnswersFiles[i + 1]?.signedUrl"
                                class="answer"
                                (click)="textToSpeechImage(question2AnswersFiles[i + 1]?.description)"
                            />
                            <div class="text-center my-1">
                                <span *ngIf="question2AnswersFiles[i + 1] != null" class="answer-container-text">{{ question2AnswersFiles[i + 1].description }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!--terza colonna-->
            <div class="d-flex flex-column px-4 my-4 horizontal-answer-possibility">
                <div class="d-flex flex-grow-2">
                    <content-answers-list
                        #contentAnswersList
                        [isDragDisabled]="exerciseExtendedDto?.isDone"
                        [state]="state"
                        [answers]="answers"
                        [contentAllowed]="contentAllowed"
                        (dragStartedEvent)="showDropContainer()"
                        (dragEndedEvent)="hideDropContainer()"
                    >
                    </content-answers-list>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #modifingCreatingTemplate>
    <div class="d-flex flex-row align-items-start classification-background">
        <div class="d-flex flex-column px-4 my-4 horizontal-classification-container">
            <p class="text-blue pb-2 text-bold h4">
                {{ 'ShadowCAAQuestion' | localize }}
            </p>
            <div class="text-center mb-2 question-description">
                <input type="text" class="form-control" [disabled]="exerciseExtendedDto?.isDone" [(ngModel)]="snapShotObj.classification.question1.description" />
            </div>
            <div class="d-flex flex-row my-2 bg-white shadow-default question-container">
                <div class="d-flex flex-column justify-content-center">
                    <img
                        [alt]="question1File?.description"
                        [src]="question1File?.signedUrl ? question1File?.signedUrl : '/assets/img/image-placeholder-exercise.png'"
                        cdkDropList
                        id="question-list-dnd"
                        class="w-100 h-100 border-radius-default"
                        (cdkDropListDropped)="dropQuestion1($event)"
                    />
                </div>
            </div>

            <div *ngFor="let item of [].constructor(RIGHT_ANSWERS_NUMBER); let i = index; let even = even">
                <div class="d-flex flex-row align-items-start" *ngIf="even">
                    <div class="d-flex flex-column p-2 mb-2 bg-white shadow-default answer-container">
                        <img
                            [alt]="question1AnswersFiles[i]?.description"
                            [src]="question1AnswersFiles[i]?.signedUrl ? question1AnswersFiles[i]?.signedUrl : '/assets/img/image-placeholder-exercise.png'"
                            class="answer"
                            cdkDropList
                            id="rightAnswer-list-dnd"
                            (cdkDropListDropped)="dropCorrectAnswerQ1($event, i)"
                        />
                        <div class="text-center mb-2">
                            <input
                                [disabled]="exerciseExtendedDto?.isDone"
                                *ngIf="question1AnswersFiles[i] != null"
                                type="text"
                                class="form-control form-control-sm mt-1"
                                [(ngModel)]="question1AnswersFiles[i].description"
                            />
                        </div>
                    </div>
                    <div class="d-flex flex-column p-2 mb-2 bg-white shadow-default answer-container">
                        <img
                            [alt]="question1AnswersFiles[i + 1]?.description"
                            [src]="question1AnswersFiles[i + 1]?.signedUrl ? question1AnswersFiles[i + 1]?.signedUrl : '/assets/img/image-placeholder-exercise.png'"
                            class="answer"
                            cdkDropList
                            id="rightAnswer-list-dnd"
                            (cdkDropListDropped)="dropCorrectAnswerQ1($event, i + 1)"
                        />
                        <div class="text-center mb-2">
                            <input
                                [disabled]="exerciseExtendedDto?.isDone"
                                *ngIf="question1AnswersFiles[i + 1] != null"
                                type="text"
                                class="form-control form-control-sm mt-1"
                                [(ngModel)]="question1AnswersFiles[i + 1].description"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex flex-column px-4 my-4 horizontal-classification-container">
            <p class="text-blue pb-2 text-bold h4">
                {{ 'ShadowCAAQuestion' | localize }}
            </p>
            <div class="text-center mb-2 question-description">
                <input type="text" class="form-control" [disabled]="exerciseExtendedDto?.isDone" [(ngModel)]="snapShotObj.classification.question2.description" />
            </div>
            <div class="d-flex flex-row my-2 bg-white shadow-default question-container">
                <div class="d-flex flex-column justify-content-center">
                    <img
                        [alt]="question2File?.description"
                        [src]="question2File?.signedUrl ? question2File?.signedUrl : '/assets/img/image-placeholder-exercise.png'"
                        cdkDropList
                        id="question-list-dnd"
                        class="w-100 h-100 border-radius-default"
                        (cdkDropListDropped)="dropQuestion2($event)"
                    />
                </div>
            </div>

            <div *ngFor="let item of [].constructor(RIGHT_ANSWERS_NUMBER); let i = index; let even = even">
                <div class="d-flex flex-row align-items-start" *ngIf="even">
                    <div class="d-flex flex-column p-2 mb-2 bg-white shadow-default answer-container">
                        <img
                            [alt]="question2AnswersFiles[i]?.description"
                            [src]="question2AnswersFiles[i]?.signedUrl ? question2AnswersFiles[i]?.signedUrl : '/assets/img/image-placeholder-exercise.png'"
                            class="answer"
                            cdkDropList
                            id="rightAnswer-list-dnd"
                            (cdkDropListDropped)="dropCorrectAnswerQ2($event, i)"
                        />
                        <div class="text-center mb-2">
                            <input
                                [disabled]="exerciseExtendedDto?.isDone"
                                *ngIf="question2AnswersFiles[i] != null"
                                type="text"
                                class="form-control form-control-sm mt-1"
                                [(ngModel)]="question2AnswersFiles[i].description"
                            />
                        </div>
                    </div>
                    <div class="d-flex flex-column p-2 mb-2 bg-white shadow-default answer-container">
                        <img
                            [alt]="question2AnswersFiles[i + 1]?.description"
                            [src]="question2AnswersFiles[i + 1]?.signedUrl ? question2AnswersFiles[i + 1]?.signedUrl : '/assets/img/image-placeholder-exercise.png'"
                            class="answer"
                            cdkDropList
                            id="rightAnswer-list-dnd"
                            (cdkDropListDropped)="dropCorrectAnswerQ2($event, i + 1)"
                        />
                        <div class="text-center mb-2">
                            <input
                                [disabled]="exerciseExtendedDto?.isDone"
                                *ngIf="question2AnswersFiles[i + 1] != null"
                                type="text"
                                class="form-control form-control-sm mt-1"
                                [(ngModel)]="question2AnswersFiles[i + 1].description"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex flex-column px-2 my-4 shadowcaa-answers-container">
            <p class="text-blue pb-2 text-bold h4">
                {{ 'ShadowCAAAnswers' | localize }}
            </p>

            <div class="d-flex flex-grow-2">
                <content-answers-list #contentAnswersList [state]="state" [answers]="answers" [contentAllowed]="contentAllowed" (removeAnswerEvent)="removedAnswer($event)">
                </content-answers-list>
            </div>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-12 d-flex justify-content-end">
            <button type="button" class="btn btn-primary" (click)="save()">{{ 'Save' | localize }}</button>
        </div>
    </div>
</ng-template>
