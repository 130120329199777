export class AppConsts {
    static remoteServiceBaseUrl: string;
    static appBaseUrl: string;
    static appBaseHref: string; // returns angular's base-href parameter value if used during the publish

    static localeMappings: any = [];

    static readonly userManagement = {
        defaultAdminUserName: 'admin',
    };

    static readonly localization = {
        defaultLocalizationSourceName: 'PiattaformaCSR',
    };

    static readonly authorization = {
        encryptedAuthTokenName: 'enc_auth_token',
    };

    static readonly roles = {
        Root: 'Root',
        Admin: 'Admin',
        AdminCenter: 'AdminCenter',
        Operator: 'Operator',
        Client: 'Client',
        Relative: 'Relative',
    };

    static activityTypes = {
        VirtualClassroom: 1,
        Event: 2,
        Course: 3,
    };
}
