export class AppPermissions {
    // START TENANT PERMISSIONS
    static Pages_Tenants = 'Pages.Tenants';
    static Pages_Tenant_Centers = 'Pages.Tenant.Centers';
    // END TENANT PERMISSIONS

    // START ROLE PERMISSIONS
    static Pages_Roles = 'Pages.Roles';
    // END ROLE PERMISSIONS

    // START USERS PERMISSIONS
    static Pages_Users = 'Pages.Users';
    // START USER MANAGEMENT PERMISSIONS
    static User_Create = 'User.Create';
    static User_Update = 'User.Update';
    static User_Delete = 'User.Delete';
    // END USER MANAGEMENT PERMISSIONS
    // END USERS PERMISSIONS

    // START CENTER PERMISSIONS
    static Pages_Centers = 'Pages.Centers';
    // START CENTER MANAGEMENT PERMISSIONS
    static Center_Create = 'Center.Create';
    static Center_Update = 'Center.Update';
    static Center_Delete = 'Center.Delete';
    // END CENTER MANAGEMENT PERMISSIONS
    // END CENTER PERMISSIONS

    // START COURSE PERMISSIONS
    static Pages_Courses = 'Pages.Courses';
    static Course_Create = 'Course.Create';
    static Course_Update = 'Course.Update';
    static Course_Delete = 'Course.Delete';
    static Course_List = 'Course.List';

    // START USER COURSE PERMISSIONS
    static UserCourse_Create = 'UserCourse.Create';
    static UserCourse_Update = 'UserCourse.Update';
    static UserCourse_Delete = 'UserCourse.Delete';
    static UserCourse_List = 'UserCourse.List';

    // START LESSON PERMISSIONS
    static Lesson_Create = 'Lesson.Create';
    static Lesson_Update = 'Lesson.Update';
    static Lesson_Delete = 'Lesson.Delete';
    static Lesson_List = 'Lesson.List';

    // START ACTIVITIES PERMISSIONS
    static Pages_Activities = 'Pages.Activities';
    // END ACTIVITIES PERMISSIONS

    // START ACTIVITY PERMISSIONS
    static Activity_Create = 'Activity.Create';
    static Activity_Update = 'Activity.Update';
    static Activity_Delete = 'Activity.Delete';
    // END ACTIVITY PERMISSIONS

    // START EXERCISE PERMISSIONS
    static Exercise_Create = 'Activities.Exercise.Create';
    static Exercise_Delete = 'Activities.Exercise.Delete';
    static Exercise_Update = 'Activities.Exercise.Update';
    // END EXERCISE PERMISSIONS

    // START CAATYPES
    static Pages_CAATypes = 'Pages.CAATypes';
    // END CAATYPES

    // START MODELCAA PERMISSIONS
    static ModelCAA_Create = 'Activities.ModelCAA.Create';
    static ModelCAA_Update = 'Activities.ModelCAA.Update';
    static ModelCAA_Delete = 'Activities.ModelCAA.Delete';
    // END MODELCAA PERMISSIONS

    // START ACTIVITYCLIENT PERMISSIONS
    static ActivityClient_Create = 'Activities.ActivityClient.Create';
    static ActivityClient_Update = 'Activities.ActivityClient.Update';
    static ActivityClient_Delete = 'Activities.ActivityClient.Delete';
    // END ACTIVITYCLIENT PERMISSIONS

    // START DIDACTICLIBRARY
    static Pages_DidacticLibrary = 'Pages.DidacticLibrary';
    // END DIDACTICLIBRARY

    // START FILE PERMISSIONS
    static Create_File = 'Files.Create';
    static Update_File = 'Files.Update';
    static Delete_File = 'Files.Delete';
    // END FILE PERMISSIONS

    // START TUTORINGCALL PERMISSIONS
    static Tutoring_Create = 'Tutoring.Create';
    static Tutoring_Update = 'Tutoring.Update';
    static Tutoring_Delete = 'Tutoring.Delete';
    // END TUTORINGCALL PERMISSIONS

    // START NOTES PERMISSIONS
    static Pages_Notes = 'Pages.Notes';
    static Note_Create = 'Note.Create';
    // END NOTES PERMISSIONS
}
