import { Injector } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FileAnswer } from '@app/modelbasecaa/common-caa/file-answer';
import { ClassificationSnapshotModel } from '@app/modelbasecaa/vertical-classification-caa/classification-snapshot.model';
import { AppComponentBase } from '@shared/app-component-base';
import { RenderingState } from '@shared/models/enum-caa-state';
import { ContentPreview } from '@shared/models/file-info.model';
import { Subscription } from 'rxjs';
import { ExerciseDto, FileDto, FileServiceProxy, ModelCAADto } from './service-proxies/service-proxies';
import { isArray } from 'util';

export abstract class ClassificationComponentBase extends AppComponentBase {
    RIGHT_ANSWERS_NUMBER = 4;

    state: RenderingState;
    modelCaa: ModelCAADto;
    states = RenderingState;
    snapShotObj: ClassificationSnapshotModel = new ClassificationSnapshotModel();
    answers: ContentPreview[] = [];
    question1File: ContentPreview;
    question2File: ContentPreview;
    public question1AnswersFiles: ContentPreview[] = [];
    public question2AnswersFiles: ContentPreview[] = [];
    protected subscription: Subscription = new Subscription();

    fileService: FileServiceProxy;
    sanitizer: DomSanitizer;
    dropContainerVisible = false;

    contentPreviews: ContentPreview[] = [];
    contentPreviewsPromises: Promise<ContentPreview>[];
    exerciseExtendedDto: ExerciseDto;

    constructor(injector: Injector) {
        super(injector);
        this.fileService = injector.get(FileServiceProxy);
        this.sanitizer = injector.get(DomSanitizer);
    }

    getImages() {
        if (this.state !== RenderingState.create) {
            this.startLoading();
            const tasks = [];
            let snapshotObject: ClassificationSnapshotModel;
            if (this.exerciseExtendedDto?.isDone) {
                snapshotObject = JSON.parse(this.exerciseExtendedDto?.executedJson) as ClassificationSnapshotModel;
            } else {
                snapshotObject = this.snapShotObj;
            }
            ['classificationAnswers', 'question1RightAnswersId', 'question1', 'question2', 'question2RightAnswersId'].forEach((field: string) => {
                if (isArray(snapshotObject.classification[field])) {
                    snapshotObject.classification[field].forEach((value: any) => {
                        let id: number;
                        if (typeof value === 'number') {
                            id = value;
                        } else {
                            id = value.fileId;
                        }
                        if (id) {
                            tasks.push(() => this.getImage(id));
                        }
                    });
                } else {
                    tasks.push(() => this.getImage(snapshotObject.classification[field]?.fileId));
                }
            });

            this.contentPreviewsPromises = tasks.map((task) => task());

            Promise.all(this.contentPreviewsPromises)
                .then((res: ContentPreview[]) => {
                    this.contentPreviews = res;
                    this.populateAnswers();
                    this.populateQuestions();
                    this.stopLoading();
                })
                .catch((err) => {
                    console.error(err);
                    this.stopLoading();
                });
        }
    }

    getImage(id: number): Promise<ContentPreview> {
        return new Promise<ContentPreview>((resolve, reject) => {
            if (!id) {
                resolve(null);
            } else {
                this.subscription.add(
                    this.fileService.get(id).subscribe((res: FileDto) => {
                        resolve(new ContentPreview(res, undefined));
                    })
                );
            }
        });
    }

    showRightAnswer(file: ContentPreview, description) {
        const id1 = this.snapShotObj.classification.question1RightAnswersId.findIndex((el) => el == file.id);
        const id2 = this.snapShotObj.classification.question2RightAnswersId.findIndex((el) => el == file.id);
        if (id1 != -1) {
            file.description = description;
            // this.question1AnswersFiles[id1] = file;
            this.question1AnswersFiles.push(file);
        }
        if (id2 != -1) {
            file.description = description;
            this.question2AnswersFiles.push(file);
            //this.question2AnswersFiles[id2] = file;
        }
    }

    removeAnswer(answersArray, index) {
        answersArray.splice(index, 1);
    }

    checkAlreadyUsedAnswer(file: ContentPreview) {
        const id1 = this.snapShotObj.classification.question1RightAnswersId.findIndex((el) => el == file.id);
        const id2 = this.snapShotObj.classification.question2RightAnswersId.findIndex((el) => el == file.id);
        if (id1 != -1) {
            return true;
        }
        if (id2 != -1) {
            return true;
        }
        if (this.question1File?.id == file.id) {
            return true;
        }
        return this.question2File?.id == file.id;
    }

    dropQuestion1(event) {
        if (event.isPointerOverContainer) {
            this.question1File = event.item.data;
            this.snapShotObj.classification.question1 = new FileAnswer(this.question1File.id, false, this.question1File.description);
        }
    }

    dropQuestion2(event) {
        if (event.isPointerOverContainer) {
            this.question2File = event.item.data;
            this.snapShotObj.classification.question2 = new FileAnswer(this.question2File.id, false, this.question2File.description);
        }
    }
    /**  Function used in creation or modify of classification exercise
     * It adds right answers into their box when dropped by operator,
     * on question 1
     * @param event
     * @param index
     */
    dropCorrectAnswerQ1(event, index) {
        if (event.isPointerOverContainer) {
            const answerFileDropped: ContentPreview = event.item.data;
            if (!this.checkAlreadyUsedAnswer(answerFileDropped)) {
                this.question1AnswersFiles[index] = answerFileDropped;
                this.snapShotObj.classification.question1RightAnswersId[index] = answerFileDropped.id;
                this.addAnswer(answerFileDropped);
            } else {
                abp.notify.warn(this.l('ImageAlreadyUsed'));
            }
        }
    }

    /**  Function used in creation or modify of classification exercise
     * It adds right answers into their box when dropped by operator,
     * on question 2
     * @param event
     * @param index
     */
    dropCorrectAnswerQ2(event, index) {
        if (event.isPointerOverContainer) {
            const answerFileDropped: ContentPreview = event.item.data;
            if (!this.checkAlreadyUsedAnswer(answerFileDropped)) {
                this.question2AnswersFiles[index] = answerFileDropped;
                this.snapShotObj.classification.question2RightAnswersId[index] = answerFileDropped.id;
                this.addAnswer(answerFileDropped);
            } else {
                abp.notify.warn(this.l('ImageAlreadyUsed'));
            }
        }
    }

    populateAnswers() {
        const answers = [];
        this.question1AnswersFiles = [];
        this.question2AnswersFiles = [];

        let snapShotObj: ClassificationSnapshotModel;

        if (this.exerciseExtendedDto.isDone) {
            snapShotObj = JSON.parse(this.exerciseExtendedDto.executedJson) as ClassificationSnapshotModel;
        } else {
            snapShotObj = this.snapShotObj;
        }

        snapShotObj.classification.classificationAnswers.forEach((answer: FileAnswer) => {
            const file: FileDto = this.contentPreviews.find((cp: ContentPreview) => cp.id === answer.fileId);

            if (file) {
                const contentPreview: ContentPreview = new ContentPreview(file, this.sanitizer, answer.description);
                if (!answer.isHidden) {
                    answers.push(contentPreview);
                    if (this.state === RenderingState.edit) {
                        this.showRightAnswer(contentPreview, answer.description);
                    }
                } else {
                    this.showRightAnswer(contentPreview, answer.description);
                }
            }
        });
        this.answers = answers;
    }

    populateQuestions() {
        const file1 = new FileDto();
        file1.id = this.snapShotObj.classification.question1.fileId;
        const file2 = new FileDto();
        file2.id = this.snapShotObj.classification.question2.fileId;
        this.question1File = this.contentPreviews.find((cp: ContentPreview) => cp?.id === file1?.id);
        this.question2File = this.contentPreviews.find((cp: ContentPreview) => cp?.id === file2?.id);
    }

    showDropContainer() {
        this.dropContainerVisible = true;
    }

    hideDropContainer() {
        this.dropContainerVisible = false;
    }

    /**
     *
     * @param answer
     * Method called when an answer went removed from available answers list
     */
    removedAnswer(removedAnswer: ContentPreview) {
        this.question1AnswersFiles = this.question1AnswersFiles.filter((answer: ContentPreview) => answer.id != removedAnswer.id);
        this.snapShotObj.classification.question1RightAnswersId = this.snapShotObj.classification.question1RightAnswersId.filter(
            (answerId: number) => answerId != removedAnswer.id
        );
        this.question2AnswersFiles = this.question2AnswersFiles.filter((answer: ContentPreview) => answer.id != removedAnswer.id);
        this.snapShotObj.classification.question2RightAnswersId = this.snapShotObj.classification.question2RightAnswersId.filter(
            (answerId: number) => answerId != removedAnswer.id
        );
    }

    /**
     *
     * @param answerFileDropped
     * Method called when an answer went choosed. It add the answers on available answers list
     */
    addAnswer(answerFileDropped: ContentPreview) {
        if (!this.answers.some((el: ContentPreview) => el.id == answerFileDropped.id)) this.answers.push(answerFileDropped);
    }
}
