import { Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AppComponentBase } from '@shared/app-component-base';
import { ContentAnswersListComponent } from '@shared/components/content-answers-list/content-answers-list.component';
import { ContentAllowedConfiguration } from '@shared/models/content-configuration.model';
import { RenderingState } from '@shared/models/enum-caa-state';
import { ContentPreview } from '@shared/models/file-info.model';
import { CAATypeDto, ExerciseDto, FileDto, FileServiceProxy, ModelCAADto } from '@shared/service-proxies/service-proxies';
import { Observable, of, Subscription } from 'rxjs';
import { PreferencesSnapshotModel } from '@app/modelbasecaa/preferences-caa/preferences-snapshot.model';
import { FileAnswer } from '@app/modelbasecaa/common-caa/file-answer';

@Component({
    selector: 'preferences-caa',
    templateUrl: './preferences-caa.component.html',
    styleUrls: ['./preferences-caa.component.css'],
})
export class PreferencesCaaComponent extends AppComponentBase implements OnDestroy, OnInit {
    MIN_IMAGES_NUMBER = 2;

    @Input() caaType: CAATypeDto;
    @Input() state: RenderingState;
    @Input() modelCaa: ModelCAADto;
    @Input() exerciseExtendedDto: ExerciseDto;
    @Output() saveEvent: EventEmitter<ModelCAADto> = new EventEmitter<ModelCAADto>();
    @Output() notifyCorrectAnswerEvent: EventEmitter<string> = new EventEmitter<string>();
    @Output() notifyExerciseCompletedEvent: EventEmitter<string> = new EventEmitter<string>();
    contentAllowed: ContentAllowedConfiguration = new ContentAllowedConfiguration(true);

    states = RenderingState;
    snapShotObj: PreferencesSnapshotModel = new PreferencesSnapshotModel();
    availableImagesFiles: ContentPreview[] = [];
    choosenImages: ContentPreview[] = [];
    imagesNumber = 0;
    private subscription: Subscription = new Subscription();
    dropContainerVisible = false;

    contentPreviews: FileDto[] = [];

    @ViewChild('contentAnswersList') contentAnswersList: ContentAnswersListComponent;

    constructor(injector: Injector, private fileService: FileServiceProxy, private sanitizer: DomSanitizer) {
        super(injector);
    }

    ngOnInit() {}

    ngOnChanges(changes: SimpleChanges): void {
        switch (this.state) {
            case RenderingState.create:
                {
                    this.snapShotObj = new PreferencesSnapshotModel();
                }
                break;
            case RenderingState.edit:
                {
                    this.snapShotObj = JSON.parse(this.modelCaa.excerciseSnapshotJson);
                    this.populateImages();
                }
                break;
            case RenderingState.executing:
                {
                    this.snapShotObj =
                        this.exerciseExtendedDto.executedJson != null ? JSON.parse(this.exerciseExtendedDto.executedJson) : JSON.parse(this.modelCaa.excerciseSnapshotJson);
                    this.imagesNumber = this.snapShotObj.availableImages.length;
                    this.populateImages();
                }
                break;
        }
        if (this.exerciseExtendedDto?.isDone) {
            this.snapShotObj = JSON.parse(this.exerciseExtendedDto?.executedJson) as PreferencesSnapshotModel;
        }
        this.getImages();
    }

    /**
     * gets all the images from the DB, as an array of Promises, as they are completed, the component can use them
     * @public
     */
    getImages() {
        this.startLoading();
        const tasks: Promise<FileDto>[] = this.snapShotObj.availableImages.map((ai: FileAnswer) => this.getImage(ai));
        Promise.all(tasks).then((res: FileDto[]) => {
            this.contentPreviews = res;
            this.populateImages();
            this.stopLoading();
        });
    }

    /**
     * gets the image from the db, returning it as a Promise
     * @param id: fileAnswer, object of type FileAnswer.
     * @public
     */
    getImage(fileAnswer: FileAnswer): Promise<FileDto> {
        return new Promise<ContentPreview>((resolve) => {
            let sub = this.fileService.get(fileAnswer.fileId).subscribe((file: FileDto) => {
                resolve(new ContentPreview(file, this.sanitizer, fileAnswer.description));
            });
            this.subscription.add(sub);
        });
    }

    /**
     * gets the image the ones cached in the component, returning it as an Observable, like a service
     * @param id: number
     * @public
     */
    fetchImage(id: number): Observable<FileDto | null> {
        return of(this.contentPreviews.find((file: FileDto) => file.id === id));
    }

    populateImages() {
        this.choosenImages = [];
        this.availableImagesFiles = [];
        this.snapShotObj.availableImages.forEach((el: FileAnswer) => {
            const file = new FileDto();
            file.id = el.fileId;
            let sub = this.fetchImage(file.id).subscribe((file) => {
                if (this.state === RenderingState.edit) {
                    this.availableImagesFiles.push(new ContentPreview(file, this.sanitizer, el.description));
                } else if (el.isHidden) {
                    this.choosenImages.push(new ContentPreview(file, this.sanitizer, el.description));
                } else {
                    this.availableImagesFiles.push(new ContentPreview(file, this.sanitizer, el.description));
                }
            });
            this.subscription.add(sub);
        });
    }

    save() {
        this.snapShotObj.availableImages = [];
        this.availableImagesFiles.forEach((file: ContentPreview) => {
            this.snapShotObj.availableImages.push(new FileAnswer(file.id, false, file.description));
        });
        this.modelCaa.excerciseSnapshotJson = JSON.stringify(this.snapShotObj);
        this.saveEvent.emit(this.modelCaa);
    }

    isExerciseValid() {
        return this.availableImagesFiles.length > 1;
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    getHalfImagesLength() {
        return Math.floor(this.imagesNumber / 2);
    }
    imageChoosed(event, index) {
        if (event.isPointerOverContainer) {
            const imageChoosedFile: ContentPreview = event.item.data;
            this.choosenImages[index] = imageChoosedFile;
            this.availableImagesFiles = this.availableImagesFiles.filter((el: ContentPreview) => el.id != imageChoosedFile.id);
            const hidingIndex = this.snapShotObj.availableImages.findIndex((el: FileAnswer) => el.fileId == imageChoosedFile.id);
            this.snapShotObj.availableImages[hidingIndex].isHidden = true;
            if (this.choosenImages.length == this.getHalfImagesLength()) {
                this.availableImagesFiles = this.choosenImages;
                this.imagesNumber = this.availableImagesFiles.length;
                this.choosenImages = [];
                this.snapShotObj.availableImages = this.snapShotObj.availableImages.filter((el) => el.isHidden);
                this.snapShotObj.availableImages.forEach((el) => (el.isHidden = !el.isHidden));
                if (this.choosenImages.length == 1) this.notifyExerciseCompletedEvent.emit();
            }
            this.notifyCorrectAnswerEvent.emit(JSON.stringify(this.snapShotObj));
        }
    }

    dragStarted(answer: ContentPreview) {
        this.textToSpeechImage(answer.description);
        this.dropContainerVisible = true;
    }

    dragEnded() {
        this.dropContainerVisible = false;
    }
}
