import { FileAnswer } from '../common-caa/file-answer';

class ReconstructSequenceQuestion {
    question: FileAnswer = new FileAnswer('', false);
    rightAnswersOrdered: FileAnswer[] = [];
    answersNumber = 2;
}

export class ReconstructSequenceSnapshotModel {
    sequence: ReconstructSequenceQuestion;
    constructor() {
        this.sequence = new ReconstructSequenceQuestion();
    }
}
