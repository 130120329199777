<ng-container *ngIf="state === states.executing; else modifingCreatingTemplate">
    <div class="backgroung-container my-2">
        <p class="text-darkblue text-center px-2 mb-2 pt-4">{{ 'ClassificationInstruction' | localize }}</p>
        <div class="d-flex flex-row align-items-start justify-content-center" cdkDropListGroup>
            <div class="d-flex flex-column px-4 mb-4 vertical-classification-container">
                <div class="text-heading text-blue text-center mb-2">
                    <h4>{{ question1File?.description }}</h4>
                </div>
                <div class="question-container shadow-default">
                    <img
                        alt=""
                        [src]="question1File?.signedUrl ? question1File?.signedUrl : '/assets/img/image-placeholder-exercise.png'"
                        (click)="textToSpeechImage(question1File?.description)"
                        class="w-100 h-100 border-radius-default"
                    />
                </div>

                <div *ngFor="let item of [].constructor(RIGHT_ANSWERS_NUMBER); let i = index">
                    <div
                        *ngIf="!question1AnswersFiles[i]?.signedUrl"
                        class="answer-container p-2 bg-white shadow-default"
                        [ngClass]="dropContainerVisible ? 'drop-container' : ''"
                        cdkDropList
                        (cdkDropListDropped)="dropAnswerOnQuestion($event, snapShotObj.classification.question1RightAnswersId)"
                    ></div>

                    <div *ngIf="question1AnswersFiles[i]?.signedUrl" class="p-2 bg-white shadow-default answer-container">
                        <img alt="" [src]="question1AnswersFiles[i]?.signedUrl" class="answer" (click)="textToSpeechImage(question1AnswersFiles[i]?.description)" />
                        <div class="text-center my-1">
                            <span *ngIf="question1AnswersFiles[i] != null" class="answer-container-text">{{ question1AnswersFiles[i].description }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <!--seconda colonna-->
            <div class="d-flex flex-column px-4 mb-4 vertical-classification-container">
                <div class="text-heading text-blue text-center mb-2">
                    <h4>{{ question2File?.description }}</h4>
                </div>
                <div class="shadow-default question-container">
                    <img
                        alt=""
                        [src]="question2File?.signedUrl ? question2File?.signedUrl : '/assets/img/image-placeholder-exercise.png'"
                        (click)="textToSpeechImage(question2File?.description)"
                        class="w-100 h-100 border-radius-default"
                    />
                </div>

                <div *ngFor="let item of [].constructor(RIGHT_ANSWERS_NUMBER); let i = index">
                    <div
                        *ngIf="!question2AnswersFiles[i]?.signedUrl"
                        class="answer-container p-2 bg-white shadow-default"
                        [ngClass]="dropContainerVisible ? 'drop-container' : ''"
                        cdkDropList
                        (cdkDropListDropped)="dropAnswerOnQuestion($event, snapShotObj.classification.question2RightAnswersId)"
                    ></div>

                    <div *ngIf="question2AnswersFiles[i]?.signedUrl" class="p-2 bg-white shadow-default answer-container">
                        <img alt="" [src]="question2AnswersFiles[i]?.signedUrl" class="answer" (click)="textToSpeechImage(question2AnswersFiles[i]?.description)" />
                        <div class="text-center my-1">
                            <span *ngIf="question2AnswersFiles[i] != null" class="answer-container-text">{{ question2AnswersFiles[i].description }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <!--terza colonna-->
            <div class="d-flex flex-column px-4 mb-4 vertical-answer-possibility">
                <div class="d-flex flex-grow-2">
                    <content-answers-list
                        #contentAnswersList
                        [isDragDisabled]="exerciseExtendedDto.isDone"
                        [state]="state"
                        [answers]="answers"
                        (dragStartedEvent)="showDropContainer()"
                        (dragEndedEvent)="hideDropContainer()"
                    >
                    </content-answers-list>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #modifingCreatingTemplate>
    <div class="d-flex flex-row align-items-start backgroung-container">
        <div class="d-flex flex-column px-4 my-4 vertical-classification-container">
            <p class="text-blue pb-2 text-bold h4">
                {{ 'ShadowCAAQuestion' | localize }}
            </p>
            <div class="text-center mb-2">
                <input type="text" class="form-control" [disabled]="exerciseExtendedDto?.isDone" [(ngModel)]="snapShotObj.classification.question1.description" />
            </div>

            <div class="shadow-default question-container">
                <img
                    alt=""
                    [src]="question1File?.signedUrl ? question1File?.signedUrl : '/assets/img/image-placeholder-exercise.png'"
                    cdkDropList
                    id="question-list-dnd"
                    class="w-100 h-100 border-radius-default"
                    (cdkDropListDropped)="dropQuestion1($event)"
                />
            </div>
            <div *ngFor="let item of [].constructor(RIGHT_ANSWERS_NUMBER); let i = index">
                <div class="p-2 bg-white shadow-default answer-container">
                    <img
                        alt=""
                        [src]="question1AnswersFiles[i]?.signedUrl ? question1AnswersFiles[i]?.signedUrl : '/assets/img/image-placeholder-exercise.png'"
                        cdkDropList
                        id="rightAnswer-list-dnd"
                        class="answer"
                        (cdkDropListDropped)="dropCorrectAnswerQ1($event, i)"
                    />
                    <div class="text-center mb-2">
                        <input
                            *ngIf="question1AnswersFiles[i] != null"
                            [disabled]="exerciseExtendedDto?.isDone"
                            type="text"
                            class="form-control"
                            [(ngModel)]="question1AnswersFiles[i].description"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex flex-column px-2 my-4 vertical-classification-container">
            <p class="text-blue pb-2 text-bold h4">
                {{ 'ShadowCAAQuestion' | localize }}
            </p>
            <div class="text-center mb-2">
                <div *ngIf="state == states.edit || state == states.create" class="label">
                    <input type="text" class="form-control" [disabled]="exerciseExtendedDto?.isDone" [(ngModel)]="snapShotObj.classification.question2.description" />
                </div>
            </div>
            <div class="shadow-default question-container">
                <img
                    alt=""
                    [src]="question2File?.signedUrl ? question2File?.signedUrl : '/assets/img/image-placeholder-exercise.png'"
                    cdkDropList
                    id="question-list-dnd"
                    class="w-100 h-100 border-radius-default"
                    (cdkDropListDropped)="dropQuestion2($event)"
                />
            </div>
            <div *ngFor="let item of [].constructor(RIGHT_ANSWERS_NUMBER); let i = index">
                <div class="p-2 bg-white shadow-default answer-container">
                    <img
                        alt=""
                        [src]="question2AnswersFiles[i]?.signedUrl ? question2AnswersFiles[i]?.signedUrl : '/assets/img/image-placeholder-exercise.png'"
                        cdkDropList
                        id="question-list-dnd"
                        class="answer"
                        (cdkDropListDropped)="dropCorrectAnswerQ2($event, i)"
                    />
                    <div class="text-center mb-2">
                        <input
                            *ngIf="question2AnswersFiles[i] != null"
                            [disabled]="exerciseExtendedDto?.isDone"
                            type="text"
                            class="form-control"
                            [(ngModel)]="question2AnswersFiles[i].description"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex flex-column px-4 my-4 shadowcaa-answers-container">
            <p class="text-blue pb-2 text-bold h4">
                {{ 'ShadowCAAAnswers' | localize }}
            </p>

            <div class="d-flex flex-grow-2">
                <content-answers-list #contentAnswersList [state]="state" [answers]="answers" [contentAllowed]="contentAllowed" (removeAnswerEvent)="removedAnswer($event)">
                </content-answers-list>
            </div>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-12 d-flex justify-content-end">
            <button type="button" class="btn btn-primary" (click)="save()">{{ 'Save' | localize }}</button>
        </div>
    </div>
</ng-template>
