<pagination-template #p="paginationApi" [id]="id" [maxSize]="maxSize" (pageChange)="pageChange.emit($event)">
    <nav>
        <ul *ngIf="!(autoHide && p.pages.length <= 1)" class="pagination m-0">
            <li *ngIf="directionLinks" class="page-item" [class.disabled]="p.isFirstPage()">
                <a *ngIf="!p.isFirstPage()" class="page-link" href="javascript:;" (click)="p.previous()">
                    <em class="fas fa-chevron-left"></em>
                </a>
                <a *ngIf="p.isFirstPage()" class="page-link" href="javascript:;">
                    <em class="fas fa-chevron-left"></em>
                </a>
            </li>

            <li *ngFor="let page of p.pages" class="page-item" [class.active]="p.getCurrent() === page.value" [style.z-index]="p.getCurrent() === page.value ? '0' : ''">
                <a class="page-link" href="javascript:;" (click)="p.setCurrent(page.value)">
                    {{ page.label }}
                </a>
            </li>

            <li *ngIf="directionLinks" class="page-item" [class.disabled]="p.isLastPage()">
                <a *ngIf="!p.isLastPage()" class="page-link" href="javascript:;" (click)="p.next()">
                    <em class="fas fa-chevron-right"></em>
                </a>
                <a *ngIf="p.isLastPage()" class="page-link" href="javascript:;">
                    <em class="fas fa-chevron-right"></em>
                </a>
            </li>
        </ul>
    </nav>
</pagination-template>
